<template>
  <!--https://ej2.syncfusion.com/vue/documentation/treegrid/getting-started-vue-3-->
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <encabezado-proyecto :title="currentProject.name + ' :: Gantt'" />
      </div>
    </div>
    <ejs-gantt :dataSource='activities' :treeColumnIndex='2' :taskFields='taskFields' :toolbar="toolbar"
      :editSettings="editSettings" :enableImmutableMode='true' :splitterSettings="splitterSettings"
      :filterSettings="filterSettings" :timelineSettings="timelineSettings"  :timezone="timezone"
      :durationUnit="durationUnit" :allowFiltering="true"  :enableCriticalPath="true"  :enableContextMenu="true"
      locale = "es-CL">
      <e-columns>
        <e-column field='TaskID' headerText='Task ID' textAlign='Right' width=0></e-column>
        <e-column field='TaskCode' headerText='Task Code' textAlign='Left' width=150></e-column>
        <e-column field='TaskName' headerText='Task Name' textAlign='Left' width=250></e-column>
        <e-column field='StartDate' headerText='Start Date' textAlign='Right' format='dd-MM-yyyy' width=140></e-column>
        <e-column field='Duration' headerText='Duration' textAlign='Right' width=80></e-column>
      </e-columns>
    </ejs-gantt>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EncabezadoProyecto from "./components/EncabezadoProyecto.vue";
import { GanttComponent as EjsGantt, ColumnsDirective as EColumns, ColumnDirective as EColumn, Edit, Selection, Toolbar, Filter,CriticalPath,ContextMenu } from '@syncfusion/ej2-vue-gantt';
export default {
  name: "ProjectGantt",
  components: {
    EncabezadoProyecto,
    "ejs-gantt": EjsGantt,
    "e-columns": EColumns,
    "e-column": EColumn
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      data:[
            { TaskID: 1,TaskName: 'Project Initiation',StartDate: new Date('04/02/2019'),EndDate: new Date('04/21/2019')},
            { TaskID: 2, TaskName: 'Identify Site location', StartDate: new Date('04/02/2019'), Duration: 4, Progress: 50,ParentId:1 },
            { TaskID: 3, TaskName: 'Perform Soil test', StartDate: new Date('04/02/2019'), Duration: 4, Progress: 50, ParentId:1   },
            { TaskID: 4, TaskName: 'Soil test approval', StartDate: new Date('04/02/2019'), Duration: 4, Progress: 50,ParentId:1 },
            { TaskID: 5, TaskName: 'Project Estimation',StartDate: new Date('04/02/2019'),EndDate: new Date('04/21/2019')},
            { TaskID: 6, TaskName: 'Develop floor plan for estimation', StartDate: new Date('04/04/2019'), Duration: 3, Progress: 50, ParentId:2  },
            { TaskID: 7, TaskName: 'List materials', StartDate: new Date('04/04/2019'), Duration: 3, Progress: 50,ParentId:2  },
            { TaskID: 8, TaskName: 'Estimation approval', StartDate: new Date('04/04/2019'), Duration: 3, Progress: 50, ParentId:2  }
        ]
      ,taskFields: {
        id: 'TaskID',
        name: 'TaskName',
        startDate: 'StartDate',
        endDate: 'EndDate',
        duration: 'Duration',
        progress: 'Progress',
        parentID: 'ParentId',
        dependency: 'Predecessor',
      },
      splitterSettings: {
        columnIndex: 4
      },
      filterSettings: {
        columns: [
        ]
      },
      toolbar: ['Add', 'Edit', 'Delete', 'Cancel', 'Update', 'Indent', 'Outdent', 'Search', 'ExpandAll', 'CollapseAll'],//'ZoomIn','ZoomOut','ZoomToFit'
      editSettings: {
        allowAdding: true,
        allowEditing: true,
        allowDeleting: true,
        allowTaskbarEditing: true,
        showDeleteConfirmDialog: true
      },
      timelineSettings: {
        timelineViewMode: 'Week',
        weekStartDay: 1
      },
      durationUnit: "Day",
      timezone: "America/Santiago"
    };
  },
  provide: {
    gantt: [Edit, Selection, Toolbar, Filter,CriticalPath,ContextMenu]
  },
  computed: {
    ...mapGetters(["currentProject", "activities"]),
    /*fields(){
      return { dataSource:this.edp, id: 'TaskID', parentID: 'parentId', text: 'name',hasChildren: 'id'  };
    }*/
  },
  watch: {

  },
  methods: {
    ...mapActions(["loadActivities", "selectProject"]),
  },
  created() {
  },
  mounted() {
    if (this.id === "current") {
      console.log("proyecto actual");
    } else {
      if (this.currentProject.id != this.id) {
        this.selectProject(this.id);
      }
    }
    this.loadActivities(this.id);
  },
};
</script>
<style></style>
