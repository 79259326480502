<template>
  <div class="container-fluid py-4">
    
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 m-auto">
              <div class="card">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-success shadow-primary border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Datos básicos"
                        :class="activeStep >= 0 ? activeClass : ''"
                        @click="setActiveStep(0)"
                      >
                        <span>Datos básicos</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Datos financieros"
                        :class="activeStep >= 1 ? activeClass : ''"
                        @click="setActiveStep(1)"
                      >
                        Datos financieros
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Resumen"
                        :class="activeStep >= 2 ? activeClass : ''"
                        @click="setActiveStep(2)"
                      >
                        Resumen
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <datos-basicos :class="activeStep === 0 ? activeClass : ''"  />
                    <datos-financieros :class="activeStep === 1 ? activeClass : ''" />
                    <resumen-proyecto :class="activeStep === 2 ? activeClass : ''" @save-project="onSaveProject"/>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations } from "vuex";
import DatosBasicos from "./components/DatosBasicos.vue";
import DatosFinancieros from './components/DatosFinancieros.vue';
import ResumenProyecto from "./components/ResumenProyecto.vue";
import setMaterialSelect from "@/assets/js/material-select.js";
import setMaterialInput from "@/assets/js/material-input.js";
export default {
  name: "NewDocument",
  components: {
    DatosBasicos,
    DatosFinancieros,
    ResumenProyecto
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      formSteps: 4,
    };
  },
  computed:{
    ...mapGetters(["activeStep","project"])
  },
  watch:{
    activeStep(){
      setMaterialInput();
      setMaterialSelect();
    }
  },
  created() {
    this.clearProject();
  },
  methods: {
    ...mapActions(["saveProject"]),
    ...mapMutations(["setActiveStep","clearProject"]),
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.setActiveStep(this.activeStep+1);
       } else {
        this.setActiveStep(this.activeStep-1);
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.setActiveStep(this.activeStep-1);
      }
    },
    onSaveProject(){
      this.$swal({
        title: "¿Estás seguro?",
        text: "Confirma que deseas crear un nuevo proyecto",
        cancelButtonText: "Cancelar",
        type: "confirm",
        showCancelButton: true,
        confirmButtonColor: "#66bb6a",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, estoy seguro",
      }).then((result) => {
        if (result.value) {
          this.saveProject(this.project);
        }
      });
    }
  }
};
</script>
