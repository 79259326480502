<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Datos básicos</h5>
    <p class="mb-0 text-sm">Ingresa la información básica necesaria para la creación del proyecto</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 my-2">
          <material-input id="name" label="Nombre" variant="dynamic" 
            :default-value="project.name"
            :success="project.name.length>0"
            :error="project.name.length==0"
            :is-required="true"
            @value-changed="(val) => {project.name=val}"
          ><i class="ni ni-circle-08 mt-3"></i></material-input>
        </div>
        <div class="col-3 my-2">
          <material-input id="code" label="Código" variant="dynamic" 
            :default-value="project.code"
            :success="project.code.length>0"
            :error="project.code.length==0"
            :is-required="true"
            @value-changed="(val) => {project.code=val}"
          ><i class="ni ni-circle-08 mt-3"></i></material-input>
        </div>
        <div class="col-3 my-2">
          <material-input id="priority" label="Prioridad" variant="dynamic" 
            :default-value="project.priority"
            :success="project.priority>0"
            :error="project.priority==0"
            :is-required="true"
            @value-changed="(val) => {project.priority=val}"
          ><i class="ni ni-circle-08 mt-3"></i></material-input>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 my-2">
          <select
            id="template"
            class="form-control"
            v-model="project.template"
          >
            <option v-for="option in templates" :key="option.code" :value="option.code">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-3" v-if="project.template=='generica'">
          <vue-good-table
            :columns="columnsArea"
            :line-numbers="false"
            :search-options="{
              enabled: false,
              placeholder: 'Buscar...',
            }"
            :pagination-options="{
              enabled: false,
              mode: 'records',
            }"
            :sort-options="{
              enabled: false
            }"
            :rows="project.tiposArea"
            style-class="vgt-table striped bordered condensed"
            compactMode
        >
          <template #table-row="props">
            <div v-if="props.column.field == 'name'">
                {{ props.row.priority }}. {{ props.row.name }}
            </div>
            <div v-if="props.column.field == 'quantity'">
              <input class="form-control" v-model="props.row[props.column.field]" @change="
                  changeCell(
                    props.row[props.column.field],
                    props.column.field,
                    props.row.originalIndex
                  )
                " 
              />
            </div>
          </template>
        </vue-good-table>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Anterior</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          :disabled="!isValid"
          @click="this.$parent.nextStep"
          >Siguiente</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/ui/MaterialInput.vue";
import MaterialButton from "@/components/ui/MaterialButton.vue";
import { mapGetters,mapActions } from "vuex";
import Choices from "choices.js";
export default {
  name: "AgregarDestinatario",
  components: {
    MaterialInput,
    MaterialButton,
  },
  props: {
  },
  data(){
    return {
      templates:[
        {code:"generica",label:"generica"}
        ,{code:"plantilla-1",label:"plantilla-1"}
        , {code:"plantilla-2",label:"plantilla-2"}
      ],
      columnsArea: [
        { field: "name", label: "Tipo de área" },
        { field: "quantity", label: "Cantidad" },
      ]
    }
  },
  computed:{
    ...mapGetters(["project","tiposArea"]),
    isValid(){
      return this.project.name&&this.project.code
    }
  },
  watch:{
    tiposArea(tiposArea){
      this.reloadTiposArea(tiposArea);
    }
  },
  methods:{
    ...mapActions(["loadTiposArea"]),
    reloadTiposArea(_tiposArea){
      const tiposArea=_tiposArea.map((item )=> { return {...item,quantity:0} });
      tiposArea.sort((item1,item2) => item1.priority - item2.priority);
      this.project.tiposArea=tiposArea;
    },
    changeCell(changedData, column, row) {
      this.project.tiposArea[row][column] = changedData;
    },
  },
  mounted(){
    if (document.getElementById("template")) {
      let element = document.getElementById("template");
      new Choices(element, {
        searchEnabled: false,
      });
      element.addEventListener('change', (event) => {
        const selectedValue = event.target.value;
        this.project.template=selectedValue;
      });
    }
    if(this.tiposArea.length==0){
      this.loadTiposArea();
    }else{
      this.reloadTiposArea(this.tiposArea);
    }
  }
};
</script>
