import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

const emptyComponente = {
  id: "",
  name: "",
  code: "",
  priority: "1",
  subcomponents: [],
}

const state = {
  selectedComponente: { ...emptyComponente },
  componentes: [],
  savingComponente: false
};

export default {
  state,
  getters: {
    selectedComponente: (state) => state.selectedComponente,
    componentes: (state) => state.componentes,
    savingComponente: (state) => state.savingComponente
  },
  mutations: {
    selectComponente(state, id) {
      if (id === "") {
        state.selectedComponente = { ...emptyComponente };
      } else {
        if (!state.componentes.find(item => item.id === id)) {
          return;
        }
        state.selectedComponente = { ...state.componentes.find(item => item.id === id) }
      }
    },
    setComponentes(state, data) {
      state.componentes = data;
    },
    savingComponente(state, data) {
      state.savingComponente = data;
    }
  },
  actions: {
    async saveComponente({ commit, dispatch }, data) {
      commit("savingComponente", true);
      commit("loading", true);
      try {
        const request = {
          collection: "componentes",
          object: data
        }
        const saveObject = firebase.functions().httpsCallable("saveObject");
        const saveObjectResponse = await saveObject(request)
        //const savedObject = { ...saveObjectResponse.data.object };
        commit("message", saveObjectResponse.data.message);
        commit("savingComponente", false);
        commit("loading", false);
        dispatch("loadComponentes");
        return true;
      } catch (error) {
        commit("setError", error.name || error.message)
        commit("savingComponente", false);
        commit("loading", false);
        return false;
      }
    },
    async deleteComponente({ commit, dispatch }, data) {
      try {
        commit("deleting", true);
        commit("loading", true);
        const removeObject = firebase.functions().httpsCallable("removeObject");
        const removeObjectResponse = await removeObject({ collection: "componentes", id: data });
        commit("deleting", false);
        commit("loading", false);
        commit("message", removeObjectResponse.data.message);
        dispatch("loadComponentes");
      } catch (error) {
        commit("setError", error.name || error.message)
        commit("deleting", false);
        commit("loading", false);
      }
    },
    async loadComponentes({ commit }) {
      try {
        commit("loading", true);
        const loadObjects = firebase.functions().httpsCallable("loadObjects");
        const loadObjectsResponse = await loadObjects({ collection: "componentes" });
        commit("setComponentes", loadObjectsResponse.data)
        commit("message", "Componentes cargados");
        commit("loading", false);
      } catch (error) {
        commit("loading", false);
        commit("setError", error.name || error.message)
        if (error.code && error.code.startsWith("auth")) {
          commit("setSessionExpired", true);
        }
      }
    },
  },
  created() {

  },
};
