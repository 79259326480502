<template>
  <nav id="navbarBlur" class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl" v-bind="$attrs"
    data-scroll="true" :class="settings.isAbsolute ? 'mt-4' : 'mt-4'">
    <div class="px-4 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :color="color" />
      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0" :class="settings.isRTL ? 'px-0' : 'me-sm-4'">
        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line" :class="color ? 'bg-white' : ''"></i>
            <i class="sidenav-toggler-line" :class="color ? 'bg-white' : ''"></i>
            <i class="sidenav-toggler-line" :class="color ? 'bg-white' : ''"></i>
          </div>
        </a>
      </div>
      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0"
        :class="settings.isRTL ? 'px-0' : 'me-sm-4'">
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item dropdown d-flex align-items-center" :class="settings.isRTL ? 'ps-2' : 'pe-2'">
            <a id="dropdownMenuButton" href="#" class="p-0 nav-link lh-1" :class="[color, showMenu ? 'show' : '']"
              data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
              <i class="material-icons cursor-pointer"> notifications </i>
            </a>
            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton">
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img src="../../assets/img/nr.jpg" class="avatar avatar-sm me-3" alt="user image" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Haz recibido un nuevo documento</span> de
                        Norman Riquelme
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        hace 13 minutos
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item d-flex align-items-center">
            <a id="signOutButton" href="#" class="p-0 nav-link lh-1" :class="[color, showMenu ? 'show' : '']"
              data-bs-toggle="dropdown" aria-expanded="false" title="Cerrar sesión" @click.prevent="signOutHandler">
              <i class="material-icons cursor-pointer"> logout </i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
  },
  props: {
    minNav: {
      type: Function,
      default: () => { },
    },
    color: {
      type: String,
      default: "text-body",
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["isSignedIn","sessionExpired"]),
    currentRouteName() {
      return this.$route.name ? this.$route.name : "undefined";
    },
  },
  watch: {
    isSignedIn(val) {
      if (!val) {
        this.$router.push("/login");
      }
    }
    ,sessionExpired(val){
      if(val){
        try{
          this.$router.push("/login");
        }catch(error){
          console.log(error);
        }
      }
    }
  },
  created() {
    this.minNav;
    this.refreshSession();
    /*if (!this.isSignedIn) {
      this.$router.push("/login");
    }*/
  },

  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["signOut","refreshSession"]),
    signOutHandler() {
      this.signOut();
    },
    toggleSidebar() {
      this.navbarMinimize();
    },
  },
};
</script>
