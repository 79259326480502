<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }}, Bonasolvo
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                href="https://www.api-ux.com"
                class="nav-link text-muted"
                target="_blank"
                >Bonasolvo</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid py-4">
      <div class="position-fixed top-1 end-1 z-index-2">
        <material-snackbar
          v-if="hasMessage"
          title="Mensaje de Becca"
          date="hace un instante"
          :description="message"
          :icon="{ component: 'done', color: 'white' }"
          color="success"
          :close-handler="closeMessage"
        />
        <material-snackbar
          v-if="hasError"
          title="Error en Becca"
          date="hace un instante"
          :description="error"
          :icon="{ component: 'campaign', color: 'white' }"
          color="danger"
          :close-handler="closeError"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import MaterialSnackbar from "@/components/ui/MaterialSnackbar.vue";
import { mapGetters, mapActions,mapMutations } from "vuex";
export default {
  name: "AppFooter",
  components: {
    MaterialSnackbar,
  },
  computed: {
    ...mapGetters(["message", "error", "hasError", "hasMessage","redirect"]),
  },
  watch:{
    redirect(val){
      if(val){
        this.clearRedirect();
        this.$router.push(val);
      }
    },
    hasMessage(val){
      if(val){
        setTimeout(function(scope) {
             scope.closeMessage();
        }, 5000, this);
      }
    },
    hasError(val){
      if(val){
        setTimeout(function(scope) {
             scope.closeError();
        }, 5000, this);
      }
    }
  },
  methods: {
    ...mapMutations(["clearMessage","clearRedirect"]),
    ...mapActions(["clearError"]),
    closeMessage() {
      this.clearMessage();
    },
    closeError() {
      this.clearError();
    },
  },
};
</script>
