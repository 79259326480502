<!--
=========================================================
* Vue Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <sidenav
    v-if="settings.showSidenav"
    :custom_class="settings.color"
    :class="[settings.isRTL ? 'fixed-end' : 'fixed-start']"
  />
  
  <main
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
   <spinner/>
    <!-- nav -->
    <navbar
      v-if="settings.showNavbar"
      :class="[settings.isNavFixed ? settings.navbarFixed : '', settings.isAbsolute ? settings.absolute : '']"
      :color="settings.isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />
    <router-view />
    <app-footer v-show="settings.showFooter" />
    <configurator
      :toggle="toggleConfigurator"
      :class="[settings.showConfig ? 'show' : '', settings.hideConfigButton ? 'd-none' : '']"
      :min-nav="navbarMinimize"
    />
  </main>
</template>
<script>
/* eslint-disable */
import Sidenav from "./examples/Sidenav";
import Spinner from "./examples/Spinner";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
    Spinner
  },
  computed: {
    ...mapState([
      "settings"
    ])
  },
  beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"])
  }
};
</script>
<style>
@import "@syncfusion/ej2-base/styles/material.css";
@import "@syncfusion/ej2-buttons/styles/material.css";
@import "@syncfusion/ej2-calendars/styles/material.css";
@import "@syncfusion/ej2-dropdowns/styles/material.css";
@import "@syncfusion/ej2-grids/styles/material.css";
@import "@syncfusion/ej2-inputs/styles/material.css";
@import "@syncfusion/ej2-layouts/styles/material.css";
@import "@syncfusion/ej2-navigations/styles/material.css";
@import "@syncfusion/ej2-popups/styles/material.css";
@import "@syncfusion/ej2-splitbuttons/styles/material.css";
@import "@syncfusion/ej2-treegrid/styles/material.css";
 
@import "@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "@syncfusion/ej2-vue-gantt/styles/material.css";
@import "@syncfusion/ej2-vue-grids/styles/material.css";
@import "@syncfusion/ej2-vue-lists/styles/material.css";
@import "@syncfusion/ej2-vue-navigations/styles/material.css";
@import "@syncfusion/ej2-vue-treegrid/styles/material.css";

.dataTable-pagination-list .active a {
  background-image: linear-gradient(
    195deg,
    #ffddac 0%,
    #ffce00 100%
  ) !important;
}
</style>
