<template>
  <li class="nav-item">
    <sidenav-collapse :loading="loadingProjects" collapse-ref="projects" nav-text="Mis proyectos" :class="getMainRoute() === 'projects' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">house_siding</i>
      </template>
      <template #list>
        <ul class="nav pe-0">
          <sidenav-item v-for="project in projects" :to="`/projects/${project.id}`" icon="engineering"
            :text="project.name" :key="project.id">
          </sidenav-item>
        </ul>
      </template>
    </sidenav-collapse>
  </li>
  <li v-if="currentProject.id" class="nav-item" :class="getRoute() === 'edp' ? 'active' : ''">
    <sidenav-collapse collapse-ref="edp" :nav-text="currentProject.name"
      :class="getMainRoute() === 'projects' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">house_siding</i>
      </template>
      <template #list>
        <ul class="nav pe-0">
          <!-- nav links -->
          <sidenav-item :to="getPath('edp')" icon="engineering" text="EDP">
          </sidenav-item>
          <sidenav-item :to="getPath('edc')" icon="foundation" text="EDC">
          </sidenav-item>
          <sidenav-item :to="getPath('gantt')" icon="scheduler" text="Gantt">
          </sidenav-item>
        </ul>
      </template>
    </sidenav-collapse>
  </li>
</template>
<script>
import { mapGetters, mapActions,mapMutations } from "vuex";
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
export default {
  name: "CurrentProject",
  components: {
    SidenavItem,
    SidenavCollapse
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["currentProject", "loadingProject", "loadingProjects", "projects","loggedInUser"]),
    to() {
      return "/projects/" + this.currentProject.id;
    },
  },
  watch:{
    loggedInUser(value){
      if(value!=null){
        this.loadProjects();
      }else{
        this.clearProjects();
      }
    }
  },
  methods: {
    ...mapActions(["loadProjects"]),
    ...mapMutations(["clearProjects"]),
    getMainRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[routeArr.length - 1];
    },
    getPath(path) {
      const projectId = this.currentProject.id ? this.currentProject.id : "current"
      return "/projects/" + projectId + (path ? "/" + path : "");
    },
  }
  ,  mounted() {

  },
};
</script>
