<template>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="card mt-4" v-if="!sessionExpired">
          <div class="card-header">
            <h5 class="mb-3">Áreas</h5>
            <div
              class="btn-toolbar justify-content-between"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <toolbar-button
                icon="add"
                color="success"
                title="Agregar tipo de área"
                @click="addHandler"
                :loading="false"
              />
            </div>
            <vue-good-table
              :columns="columns"
              :line-numbers="false"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar...',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
              }"
              :rows="tiposArea"
              style-class="vgt-table striped bordered condensed"
              compactMode
            >
              <template #table-row="props">
                <div v-if="props.column.field == 'name'">
                  <span
                    class="mr-2 cursor-pointer"
                    @click.prevent="selectHandler(props.row)"
                    >{{ props.row.name }} ( {{ props.row.code }} )</span
                  >
                </div>
                <span
                  v-else-if="props.column.field == 'actions'"
                  class="cursor-pointer"
                >
                  <a href="#" @click.prevent="deleteHandler(props.row)">
                    <i class="ni ni-fat-remove mx-2"></i>
                  </a>
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal-viewer :visible="editing" title="Tipo de área" @on-close="onClose">
      <slot>
        <div class="row">
          <div class="col-6 my-2">
            
            <material-input
              id="code"
              label="Código"
              variant="dynamic"
              :default-value="tipoArea.code"
              :success="tipoArea.code.length > 0"
              :error="tipoArea.code.length == 0"
              :is-required="true"
              @value-changed="
                (val) => {
                  tipoArea.code = val;
                }
              "
              ><i class="ni ni-circle-08 mt-3"></i
            ></material-input>
          </div>
          <div class="col-6 my-2">
            
            <material-input
              id="priority"
              label="Prioridad"
              variant="dynamic"
              :default-value="tipoArea.priority"
              :success="tipoArea.priority > 0"
              :error="tipoArea.priority == 0"
              :is-required="true"
              @value-changed="
                (val) => {
                  tipoArea.priority = val;
                }
              "
              ><i class="ni ni-circle-08 mt-3"></i
            ></material-input>
          </div>
          <div class="col-12 my-2">
            <material-input
              id="name"
              label="Nombre"
              variant="dynamic"
              :default-value="tipoArea.name"
              :success="tipoArea.name.length > 0"
              :error="tipoArea.name.length == 0"
              :is-required="true"
              @value-changed="
                (val) => {
                  tipoArea.name = val;
                }
              "
              ><i class="ni ni-circle-08 mt-3"></i
            ></material-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 my-2">
            <h5 class="mb-2">Subáreas</h5>
              <ejs-grid 
                  :data-source='tipoArea.sectores'  
                  :edit-settings='editSettings' 
                  :toolbar='toolbar' 
                  :before-batch-add="beforeBatchAdd" 
                  :before-batch-save="beforeBatchSave" 
                  :allow-sorting="true"
                  >
                <e-columns>
                    <e-column field='code' header-text='Código' text-align='left' width=120 :allow-sorting="true"></e-column>
                    <e-column field='name' header-text='Nombre' text-align='left' :allow-sorting="true"></e-column>
                    <e-column field='quantity' header-text='Cantidad' text-align='right' width=120 :allow-sorting="true"></e-column>
                    <e-column field='priority' header-text='Prioridad' text-align='right' width=120 :allow-sorting="true"></e-column>
                </e-columns>
              </ejs-grid>
            </div>
          </div>
      </slot>
      <template #buttons>
        <button
          type="button"
          class="btn btn-primary full-width"
          @click="saveHandler()"
        >
          Aceptar
        </button>
      </template>
    </modal-viewer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import ModalViewer from "@/components/ui/ModalViewer.vue";
import ToolbarButton from "@/components/ui/ToolbarButton.vue";
import MaterialInput from "@/components/ui/MaterialInput.vue";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar, Edit,Sort
} from "@syncfusion/ej2-vue-grids";

export default {
  name: "TiposArea",
  components: {
    ModalViewer,
    ToolbarButton,
    MaterialInput,
    "ejs-grid": GridComponent,
    "e-columns": ColumnsDirective,
    "e-column": ColumnDirective,
  },
  provide: {
    grid: [Page, Edit, Toolbar,Sort]
  },
  props: {},
  data() {
    return {
      debug: process.env.VUE_APP_NODE_ENV == "development",
      columns: [
        { field: "priority", label: "Prioridad" },
        { field: "name", label: "Área" },
        { field: "actions", label: "" },
      ],
      editing: false,
      tipoArea: {
        id: "",
        code: "",
        name: "",
        priority: 1,
        sectores: [],
      },
      editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch'},
      toolbar: ['Add', 'Edit', 'Delete', 'Update', 'Cancel']
    };
  },

  computed: {
    ...mapGetters([
      "tiposArea",
      "sessionExpired",
      "loading",
      "deleting",
      "selectedTipoArea",
    ]),
    ...mapState(["settings"]),
  },
  created() {},
  mounted() {
    this.loadTiposArea();
  },
  methods: {
    ...mapMutations(["selectTipoArea"]),
    ...mapActions(["loadTiposArea", "deleteTipoArea", "saveTipoArea"]),
    selectHandler(row) {
      this.selectTipoArea(row.id);
      this.tipoArea = { ...this.selectedTipoArea };
      this.editing = true;
    },
    deleteHandler(row) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "Confirma que deseas eliminar este tipo de área",
        cancelButtonText: "Cancelar",
        type: "danger",
        showCancelButton: true,
        confirmButtonColor: "#66bb6a",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, estoy seguro",
      }).then((result) => {
        if (result.value) {
          this.deleteTipoArea(row._id);
        }
      });
    },
    addHandler() {
      this.selectTipoArea("");
      this.tipoArea = { ...this.selectedTipoArea };
      this.editing = true;
    },
    saveHandler() {
      if (this.saveTipoArea(this.tipoArea)) {
        this.editing = false;
      }
    },
    beforeBatchAdd: function (args) {
        args.defaultData.id=Date.now();
       args.defaultData.code="";
       args.defaultData.name="";
       args.defaultData.quantity=1;
       args.defaultData.priority=1;
       
    },
    beforeBatchSave: function (batch) {
      let items=this.tipoArea.sectores;
      console.log("original items",JSON.stringify(items));
      if(items&&items.length>0){
            items=[...items,...batch.batchChanges.addedRecords]        
       }else{
            items=[...batch.batchChanges.addedRecords]
      }
        //items modificados
        batch.batchChanges.changedRecords.forEach((changedItem) => {
            const index = items.findIndex((originalItem) => originalItem.id === changedItem.id);
            if(index>-1){
                if(items[index].code!=changedItem.code){
                    items[index].code=changedItem.code
                }
                if(items[index].name!=changedItem.name){
                    items[index].name=changedItem.name
                };
            }
        });
        if(batch.batchChanges.deletedRecords.length>0){
            items = items.filter((item) => {
                const index = batch.batchChanges.deletedRecords.findIndex((deletedItem) => deletedItem.id === item.id);
                return index<0;
            });
        }
      this.tipoArea.sectores=items;
    },
    onClose() {
      this.editing = false;
    },
  },
};
</script>
