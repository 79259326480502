<template>
    <div v-if="loading"
        class="text-center d-flex align-items-center justify-content-center me-2"
      >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    loading: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
