import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
//import { DataManager, WebApiAdaptor } from "@syncfusion/ej2-data";
///Volumes/Transcend/Proyectos/Becca/GIT/becca-dashboard/node_modules/@syncfusion/ej2-data/src/adaptors.js
/*WebApiAdaptor.prototype.remove = function (dm, keyField, value, tableName) {
  console.log("keyField",keyField);
  console.log("tableName",tableName);
  return {
      type: 'DELETE',
      url: dm.dataSource.url + '/' + value,
      //data: JSON.stringify(value)
  };
}; */

const emptyProject = {
  id: "",
  name: "",
  code:"",
  priority:"0",
  template: "generica",
  startYear: "2023",
  endYear: "2023",
  currency: "USD",
  tiposArea: []
 }

const state = {
  project: { ...emptyProject },
  currentProject: {...emptyProject},
  projects: [],
  loadingProjects: false,
  loadingProject: false,
  savingProject: false,
  savingEdp:false,
  savingEdc:false,
  deletingProject: false,
  activeStep: 0,
  edp:[],
  itemEdpSelected:{id:0,code:'',name:'',componentesEdc:[]},
  edpDataSource:null,
  activities:[],
  loadingGantt:false
};

export default {
  state,
  getters: {
    project: (state) => state.project,
    projects: (state) => state.projects,
    loadingProjects: (state) => state.loadingProjects,
    loadingProject: (state) => state.loadingProject,
    savingProject: (state) => state.savingProject,
    deletingProject: (state) => state.deletingProject,
    activeStep: (state)=> state.activeStep,
    currentProject: (state) => state.currentProject,
    edpDataSource:(state) => state.edpDataSource,
    edp: (state) => state.edp,
    savingEdp: (state)=> state.savingEdp,
    savingEdc: (state)=> state.savingEdc,
    itemEdpSelected: (state)=> state.itemEdpSelected,
    activities:(state)=>state.activities,
    loadingGantt:(state)=>state.loadingGantt
  },
  mutations: {
    setProject(state, data) {
      state.project = data;
    },
    setCurrentProject(state, data) {
      state.currentProject = data;
    },
    setProjects(state, data) {
      state.projects = data;
    },
    setActiveStep(state, data) {
      state.activeStep = data;
    },
    clearProject(state) {
      state.project = { ...emptyProject };
    },
    clearProjects(state) {
      state.project = { ...emptyProject };
      state.projects.splice();
    },
    loadingProjects(state, data) {
      state.loadingProjects = data;
    },
    loadingProject(state, data) {
      state.loadingProject = data;
    },
    savingProject(state, data) {
      state.savingProject = data;
    },
    savingEdp(state, data) {
      state.savingEdp = data;
    },
    savingEdc(state, data) {
      state.savingEdc = data;
    },
    deletingProject(state, data) {
      state.deletingProject = data;
    },
    setEdpDataSource(state,data){
      state.edpDataSource=data;
    },
    setEdp(state,data){
      state.edp=data;
    },
    setActivities(state,data){
      state.activities=data;
    },
    loadingGantt(state,data){
      state.loadingGantt=data;
    },
    selectItemEdp(state, id) {
      if (id === "") {
        state.itemEdpSelected = null;
      } else {
        if (!state.edp.find(item => item.id === id)) {
          return;
        }
        state.itemEdpSelected = { ...state.edp.find(item => item.id === id) }
      }
    },
    setEdc(state,data){
      state.itemEdpSelected=data;
    }
  },
  actions: {
    async saveProject({ commit },data) {
      commit("savingProject", true);
      commit("loading",true);
      try {
        const request={
          project:data
        }
        const saveProject = firebase.functions().httpsCallable("saveProject");
        const saveProjectResponse = await saveProject(request)
        const newProject={ ...saveProjectResponse.data.project };
        commit("setProject", newProject);
        commit("message", saveProjectResponse.data.message);
        commit("savingProject", false);
        commit("loading",false);
        commit("setActiveStep", 0);
        commit("redirect","/projects/"+newProject.id+"/edp");
      } catch (error) {
        commit("setError",error.name||error.message)
        commit("savingProject", false);
        commit("loading",false);
      }
    },
    reloadEdp({ commit },data) {
      commit("setEdp",data);
    },
    async saveEdp({ commit },data) {
      commit("savingEdp", true);
      commit("loading",true);
      try {
        const request={
          id:state.currentProject.id
          ,edp: data
        }
        const saveEdp = firebase.functions().httpsCallable("saveEdp");
        const saveEdpResponse = await saveEdp(request)
        const edp={ ...saveEdpResponse.data.edp };
        commit("setEdp", edp.items);
        commit("message", saveEdpResponse.data.message);
        commit("savingEdp", false);
        commit("loading",false);
      } catch (error) {
        commit("setError",error.name||error.message)
        commit("savingEdp", false);
        commit("loading",false);
      }
    },
    async saveEdc({ commit },data) {
      commit("savingEdc", true);
      commit("loading",true);
      try {
        const request={
          id:state.currentProject.id
          ,edc: data
        }
        const saveEdc = firebase.functions().httpsCallable("saveEdc");
        const saveEdcResponse = await saveEdc(request)
        const edc={ ...saveEdcResponse.data.edc };
        console.log("edc",JSON.stringify(edc));
        //commit("setEdp", edp.items);
        commit("message", saveEdcResponse.data.message);
        commit("savingEdc", false);
        commit("loading",false);
      } catch (error) {
        commit("setError",error.name||error.message)
        commit("savingEdc", false);
        commit("loading",false);
      }
    },
    async updateItemEdp({ commit },data) {
      commit("savingEdp", true);
      commit("loading",true);
      try {
        const request={
          id:state.currentProject.id
          ,item: data.item
          ,action: data.action
        }
        const updateItemEdp = firebase.functions().httpsCallable("updateItemEdp");
        const updateItemEdpResponse = await updateItemEdp(request)
        const edp={ ...updateItemEdpResponse.data.edp };
        commit("setEdp", edp.items);
        commit("message", updateItemEdpResponse.data.message);
        commit("savingEdp", false);
        commit("loading",false);
      } catch (error) {
        commit("setError",error.name||error.message)
        commit("savingEdp", false);
        commit("loading",false);
      }
    },
    async deleteProject({ commit,dispatch }, data) {
      try {
        commit("clearProject");
        commit("deletingProject", true);
        commit("loading",true);
        const removeProject = firebase.functions().httpsCallable("removeProject");
        const removeProjectResponse = await removeProject({id:data});
        commit("deletingProject", false);
        commit("loading",false);
        commit("message", removeProjectResponse.data.message);
        dispatch("loadProjects");
      } catch (error) {
        commit("setError",error.name||error.message)
        commit("deletingProject", false);
        commit("loading",false);
      }
    },
    async loadProjects({ commit }) {
      try {
        commit("loadingProjects", true);
        commit("loading",true);
        const loadProjects = firebase.functions().httpsCallable("loadProjects");
        loadProjects().then((response) => {
          commit("setProjects", response.data)
          commit("message", "Proyectos cargados");
          commit("loadingProjects", false);
          commit("loading",false);
        }).catch((error) => { 
          commit("setError",error.name||error.message)
          commit("loadingProjects", false);
          commit("loading",false);
        });
      } catch (error) {
        // console.error(error);
        commit("loadingProjects", false);
        commit("loading",false);
        commit("setError",error.name||error.message)
        if (error.code && error.code.startsWith("auth")) {
          commit("setSessionExpired", true);
        }
      }
    },
    async loadEdc({ commit }, data) {
      try {
        commit("loading",true);
        const loadEdc = firebase.functions().httpsCallable("loadEdc");
        const loadEdcResponse = await loadEdc({projectId:state.currentProject.id,id:data});
        const {edc,message}=loadEdcResponse.data;
        console.log("loadEdcResponse",JSON.stringify(loadEdcResponse));
        commit("loading",false);
        commit("message", message);
        if(edc!=null){
          commit("setEdc",edc);
        }else{
          commit("selectItemEdp",data);
        }
      } catch (error) {
        commit("setError",error.name||error.message)
        commit("loading",false);
      }
    },
    async loadActivities({ commit },data) {
      try {
        commit("loadingGantt",true);
        const loadGantt = firebase.functions().httpsCallable("loadGantt");
        const loadGanttResponse = await loadGantt({id:data});
        const {activities,message}=loadGanttResponse.data;
        commit("loadingGantt",false);
        commit("message", message);
        commit("setActivities",activities);
      } catch (error) {
        console.log("error",error);
        commit("setError",error.name||error.message)
        commit("loadingGantt",false);
      }
    },

    /*
    loadProject({ commit}, id) {
      try {
        commit("loadingProject", true);
        const loadProject = firebase.functions().httpsCallable("loadProject");
        loadProject({id}).then((response) => {
          commit("setProject", {...response.data})
          commit("loadingProject", false);
        }).catch((error) => { 
          commit("setError",error.name||error.message)
          commit("loadingProject", false);
        });
      } catch (error) {
        // console.error(error);
        commit("loadingProject", false);
        commit("setError",error.name||error.message)
        if (error.code && error.code.startsWith("auth")) {
          commit("setSessionExpired", true);
        }
      }
    },*/
    selectProject({ commit}, id) {
      try {
        commit("loadingProject", true);
        commit("loading",true);
        const loadProject = firebase.functions().httpsCallable("loadProject");
        loadProject({id}).then(async (response) => {
          
          
          /*const currentUser = firebase.auth().currentUser;
          const accessToken=await currentUser.getIdToken();
          const functionsBaseURL=process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN;
          const SERVICE_URI =functionsBaseURL+"/edpApi/items?pid="+id;
          const dataSource=new DataManager({
            url: SERVICE_URI,
            adaptor: new WebApiAdaptor(),
            headers: [{ Authorization: `Bearer ${accessToken}`}],
            offline: false
          });
          commit("setEdpDataSource",dataSource);
          */
         const project={...response.data};
          commit("setEdp",project.edp.items);
          commit("setCurrentProject", project)
          commit("loadingProject", false);
          commit("loading",false);
        }).catch((error) => { 
          commit("setError",error.name||error.message)
          commit("loadingProject", false);
          commit("loading",false);
        });
      } catch (error) {
        // console.error(error);
        commit("loadingProject", false);
        commit("setError",error.name||error.message)
        if (error.code && error.code.startsWith("auth")) {
          commit("setSessionExpired", true);
        }
      }
        //commit("redirect", "/projects/"+id);      
    },
  },
  created() {

  },
};
