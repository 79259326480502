<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <encabezado-proyecto :title="currentProject.name"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapActions} from 'vuex';
import EncabezadoProyecto from './components/EncabezadoProyecto.vue';
export default {
  name: "ProjectInfo",
  components: {
    EncabezadoProyecto
  },
  props: {
    id: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...mapGetters(["currentProject","sessionExpired"])
  },
  created() {
    
  },
  watch:{
    id(val){
      this.selectProject(val);
    }
  },
  mounted() {
    //if(this.id!="current"){
      if(this.currentProject.id!=this.id){
        this.selectProject(this.id);
      }
    //}
  },
  methods: {
    ...mapActions(["selectProject"])
  }
};
</script>
