<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li v-if="loggedInUser" class="nav-item" :class="getRoute() === 'profile' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="currentUser"
          :nav-text="loggedInUser?loggedInUser.displayName:''"
          :class="getRoute() === 'profile' ? 'active' : ''"
        >
          <template #icon>
            <img :src="loggedInUser&&loggedInUser.photoUrl" class="avatar" referrerPolicy="no-referrer"/>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Settings' }"
                mini-icon="MP"
                text="Mi perfil"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-else><spinner :loading="true"></spinner></li>
      <current-project/>  
      <li><hr class="horizontal bg-secondary  m-2 " /></li>
      <li class="nav-item" :class="getRoute() === 'projects' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="proyectos"
          nav-text="Proyectos"
          :class="getRoute() === 'projects' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">group</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
                <sidenav-item
                  :to="{ name: 'Nuevo Proyecto' }"
                  mini-icon="N"
                  text="Nuevo Proyecto"
                />
              <sidenav-item
                :to="{ name: 'Proyectos' }"
                mini-icon="P"
                text="Proyectos"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>      
      <li><hr class="horizontal bg-secondary  m-2 " /></li>
      <li class="nav-item" :class="getRoute() === 'admin' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="admin"
          nav-text="Administración"
          :class="getRoute() === 'admin' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">group</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item
                :to="{ name: 'Actividades' }"
                mini-icon="A"
                text="Actividades"
              />
              <sidenav-item
                :to="{ name: 'Tipos de área' }"
                mini-icon="T"
                text="Tipos de área"
              />
              <sidenav-item
              :to="{ name: 'Sistemas' }"
              mini-icon="S"
              text="Sistemas"
              />
              <sidenav-item
                :to="{ name: 'Componentes' }"
                mini-icon="C"
                text="Componentes"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import Spinner from "../../components/ui/Spinner.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import CurrentProject from "./CurrentProject.vue";
import { mapState,mapGetters } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    CurrentProject,
    Spinner
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  computed: {
    ...mapState(["settings","isRTL"]),
    ...mapGetters(["loggedInUser","loadingUser"]),
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
