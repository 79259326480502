<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Resumen proyecto</h5>
    <p class="mb-0 text-sm">Reumen de la información del proyecto</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-8 mt-3">
          <div class=" input-group input-group-dynamic is-filled">
            <label class="form-label">Nombre</label>
            <span class="input-form">{{project.name}}</span>
          </div>  
        </div>
        <div class="col-12 mt-3">
          <div class="input-group input-group-dynamic is-filled">
            <label class="form-label">Código</label>
            <span class="input-form">{{project.code}}</span>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="input-group input-group-dynamic is-filled">
            <label class="form-label">Plantilla EDP</label>
            <span class="input-form">{{project.template}}</span>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="input-group input-group-dynamic is-filled">
            <label class="form-label">Presupuesto</label>
            <span class="input-form">{{project.currency}} {{project.budget}}</span>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="input-group input-group-dynamic is-filled">
            <label class="form-label">Período ejecución</label>
            <span class="input-form">{{project.startYear}} - {{project.endYear}}</span>
          </div>
        </div>
        <div class="col-12 mt-3"  v-for="tipoArea in project.tiposArea" :key="tipoArea.id">
          <div class="input-group input-group-dynamic is-filled">
            <label class="form-label">{{tipoArea.name}}</label>
            <span class="input-form">{{tipoArea.quantity}}</span>
          </div>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Anterior</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          :disabled="!isValid"
          @click="$emit('save-project')"
          >Siguiente</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/ui/MaterialButton.vue";
import { mapGetters } from "vuex";
export default {
  name: "ResumenProyecto",
  components: {
    MaterialButton,
  },
  props: {},
  emits: ["save-project"],
  computed: {
    ...mapGetters(["project"]),
    isValid() {
      return true;
    },
  },
  methods: {},
};
</script>
