import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import {firebaseSettings} from "@/data/config";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueGoodTable from "vue-good-table-next";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import { registerLicense } from '@syncfusion/ej2-base';
import { ToolbarPlugin,TreeViewPlugin } from "@syncfusion/ej2-vue-navigations";
import { ListViewPlugin } from "@syncfusion/ej2-vue-lists";
import { ListBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";
import "./utils/localization";
registerLicense('ORg4AjUWIQA/Gnt2V1hiQlRPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXdTcURkWXdbc3JQRmY=');
firebase.initializeApp(firebaseSettings);
if(process.env.VUE_APP_CLOUD_FUNCTIONS_EMULATOR == "true"){
    firebase.functions().useEmulator('localhost',5001);
}
const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(VueGoodTable);
appInstance.use(MaterialDashboard);
appInstance.use(ToolbarPlugin);
appInstance.use(TreeViewPlugin);
appInstance.use(ListBoxPlugin);
appInstance.use(ListViewPlugin);
appInstance.mount("#app");
