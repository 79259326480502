import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

const emptySistema = {
  id: "",
  name: "",
  code: "",
  priority: "1"
}

const state = {
  selectedSistema: { ...emptySistema },
  sistemas: [],
  savingSistema: false
};

export default {
  state,
  getters: {
    selectedSistema: (state) => state.selectedSistema,
    sistemas: (state) => state.sistemas,
    savingSistema: (state) => state.savingSistema
  },
  mutations: {
    selectSistema(state, id) {
      if (id === "") {
        state.selectedSistema = { ...emptySistema };
      } else {
        if (!state.sistemas.find(item => item.id === id)) {
          return;
        }
        state.selectedSistema = { ...state.sistemas.find(item => item.id === id) }
      }
    },
    setSistemas(state, data) {
      state.sistemas = data;
    },
    savingSistema(state, data) {
      state.savingSistema = data;
    }
  },
  actions: {
    async saveSistema({ commit, dispatch }, data) {
      commit("savingSistema", true);
      commit("loading", true);
      try {
        const request = {
          collection: "sistemas",
          object: data
        }
        const saveObject = firebase.functions().httpsCallable("saveObject");
        const saveObjectResponse = await saveObject(request)
        //const savedObject = { ...saveObjectResponse.data.object };
        commit("message", saveObjectResponse.data.message);
        commit("savingSistema", false);
        commit("loading", false);
        dispatch("loadSistemas");
        return true;
      } catch (error) {
        commit("setError", error.name || error.message)
        commit("savingSistema", false);
        commit("loading", false);
        return false;
      }
    },
    async deleteSistema({ commit, dispatch }, data) {
      try {
        commit("deleting", true);
        commit("loading", true);
        const removeObject = firebase.functions().httpsCallable("removeObject");
        const removeObjectResponse = await removeObject({ collection: "sistemas", id: data.id });
        commit("deleting", false);
        commit("loading", false);
        commit("message", removeObjectResponse.data.message);
        dispatch("loadSistemas");
      } catch (error) {
        commit("setError", error.name || error.message)
        commit("deleting", false);
        commit("loading", false);
      }
    },
    async loadSistemas({ commit }) {
      try {
        commit("loading", true);
        const loadObjects = firebase.functions().httpsCallable("loadObjects");
        const loadObjectsResponse = await loadObjects({ collection: "sistemas" });
        const sistemas = loadObjectsResponse.data.sort((item1, item2) => item2.priority < item1.priority);
        commit("setSistemas",sistemas)
        commit("message", "Sistemas cargados");
        commit("loading", false);
      } catch (error) {
        commit("loading", false);
        commit("setError", error.name || error.message)
        if (error.code && error.code.startsWith("auth")) {
          commit("setSessionExpired", true);
        }
      }
    },
  },
  created() {

  },
};
