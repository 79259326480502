<template>
  <!--https://ej2.syncfusion.com/vue/documentation/treegrid/getting-started-vue-3-->
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <encabezado-proyecto :title="currentProject.name + ' :: ' + itemEdpSelected.code" />
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <div class="control_wrapper">
          <ejs-treeview id="treeview" :fields='fields' :loadOnDemand="false" :nodeSelected="nodeSelected"
            :selectedNodes="itemsSelected"></ejs-treeview>
        </div>
      </div>
      <div class="col-8" :class="isDragging ? 'dragging' : ''">
        <div v-if="itemEdpSelected.id > 0">
          <h4>{{ itemEdpSelected.name }} - {{ itemEdpSelected.code }}</h4>
          <div class="row w-100 m-1">
            <div class="col col-2 m-0 p-0 text-center droppable" v-for="sistema in sistemas" :key="sistema.id">
              {{ sistema.name }} - {{ sistema.code }}
              <div class="drop-zone" @drop="onDrop($event, sistema)" @dragover.prevent @dragenter.prevent></div>

            </div>
          </div>
          <ejs-grid :dataSource='itemEdpSelected.componentesEdc' :toolbar='toolbar' :editSettings='editSettings'
            :allowSorting="false" ref="grid">
            <e-columns>
              <e-column field='code' headerText='Código' textAlign='left' width=200 :allowSorting="false"></e-column>
              <e-column field='name' headerText='Nombre' textAlign='left' :allowSorting="false"></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
      <div class="col-2">

        <div class="componentes text-center">
          <b>Componentes</b>
          <br />
          <ejs-listbox v-if="false" :dataSource='componentes' :allowDragAndDrop="true" :fields="fieldsComponentes"
            scope="combined-list"></ejs-listbox>
          <ComponentIcon v-for="item in componentes" :key="item.id" icon="house_siding" :component="item" draggable="true"
            @dragstart="startDrag($event, item)" @dragend="stopDrag($event, item)">
          </ComponentIcon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EncabezadoProyecto from "./components/EncabezadoProyecto.vue";
import ComponentIcon from "./components/ComponentIcon.vue";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar, Edit, Sort
} from "@syncfusion/ej2-vue-grids";


export default {
  name: "ProjectEDC",
  components: {
    EncabezadoProyecto,
    ComponentIcon,
    "ejs-grid": GridComponent,
    "e-columns": ColumnsDirective,
    "e-column": ColumnDirective,
  },
  provide: {
    grid: [Page, Edit, Toolbar, Sort]
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      //componentesEdc:[],
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal', newRowPosition: "Below", showConfirmDialog: false,
        showDeleteConfirmDialog: false
      },
      toolbar: ['Add', 'Edit', 'Delete', 'Update', 'Cancel'],
      fieldsComponentes: { text: "code", value: "id", iconCss: "code" },
      dropAreaText: "Drop area",
      isDragging: false
    };
  },
  computed: {
    ...mapGetters(["currentProject", "edp", "sessionExpired", "componentes", "sistemas", "itemEdpSelected"]),
    fields() {
      return { dataSource: this.edp, id: 'id', parentID: 'parentId', text: 'code', hasChildren: 'id' };
    }
    , itemsSelected() {
      if (this.itemEdpSelected != null) {
        return [this.itemEdpSelected];
      } else {
        return [];
      }
    }
  },
  watch: {

  },
  created() {
  },
  mounted() {
    if (this.id === "current") {
      console.log("proyecto actual");
    } else {
      if (this.currentProject.id != this.id) {
        this.selectProject(this.id);
      }
    }
    if (this.componentes.length == 0) {
      this.loadComponentes();
    }
    this.loadSistemas();
  },
  methods: {
    ...mapActions(["selectProject", "saveEdp", "saveEdc", "reloadEdp", "updateItemEdp", "loadComponentes", "loadSistemas", "loadEdc"]),

    flatComponent(elementos, sistema, parent) {
      if (elementos) {
        return elementos.flatMap((elemento) => {
          const { subcomponents, ...elementoSinHijos } = elemento;
          const parentCode = sistema.code + (parent ? +"." + parent.code : "");
          //code: _sector.code+"."+project.priority+"."+indiceTipoArea+"."+indiceSubArea,
          // const code=elemento.code+"."+
          const item = {
            code: parentCode + "." + elementoSinHijos.code,
            name: elementoSinHijos.name,
            parentCode
          }

          let next = [item];
          if (elemento.actividades) {
            //console.log("elemento.actividades");
            const childs = elemento.actividades.map(actividad => {
              return {
                code: item.code + "." + actividad.code,
                name: actividad.name,
                parentCode: item.code
              }
            });
            next = [...next, ...childs];
          } else {
            console.log("elemento sin actividades");
            next = [...next, ...this.flatComponent(subcomponents, item)];
          }
          return next;
        })
      } else {

        return [];
      }
    },
    buildEdcOld(components, sistema) {
      const edc = this.flatComponent(components, sistema);
      return edc;
    },
    buildEdc(componentes, sistema) {
      const componente=componentes[0];
      const indiceComponente = componente.priority;//this.itemEdpSelected.componentesEdc.length + 1;
      const baseCode = this.itemEdpSelected.code.split(".").slice(1).join(".");
      const codeComponente = componente.code + "." + baseCode + "." + sistema.code + "." + indiceComponente;
      const items = [];
      const itemComponente = {
        code: codeComponente,
        name: componente.name,
        parentCode:this.itemEdpSelected.code
      }
      items.push(itemComponente);

      let indiceSubcomponente = 1;
      componente.subcomponents.forEach((subcomponente) => {
        const codeSubcomponente = subcomponente.code + "." + baseCode + "." + sistema.code + "." + indiceComponente + "." + indiceSubcomponente;
        const itemSubcomponente = {
          code: codeSubcomponente,
          name: subcomponente.name,
          parentCode:itemComponente.code
        }
        items.push(itemSubcomponente);

        
        let indiceActividad = 1;
        subcomponente.actividades.forEach((actividad) => {
          const codeActividad =   "ACT." + baseCode + "." + sistema.code + "." + indiceComponente + "." + indiceSubcomponente+"."+indiceActividad;
          const itemActividad = {
            code: codeActividad,
            name: actividad.name,
            parentCode: itemSubcomponente.code
          }
          items.push(itemActividad);
          indiceActividad++;
        })

        indiceSubcomponente++;
      })
      return items;
    },
    nodeSelected: function (args) {
      console.log(document);
      console.log(JSON.stringify(args));
      var treeObj = document.getElementById('treeview').ej2_instances[0];
      this.loadEdc(Number(treeObj.selectedNodes));
      //        alert("The selected node's id: " + treeObj.selectedNodes); // To alert the selected node's id.
    },
    stopDrag() {//evt.item
      this.isDragging = false;
    },
    startDrag(evt, item) {
      this.isDragging = true;
      evt.dataTransfer.dropEffect = 'link'
      evt.dataTransfer.effectAllowed = 'link'
      evt.dataTransfer.setData('item', JSON.stringify(item))
    },
    onDrop(evt, sistema) {//evt,list
      this.isDragging = false;
      const item = JSON.parse(evt.dataTransfer.getData('item'));
      if (!("componentesEdc" in this.itemEdpSelected)) {
        this.itemEdpSelected.componentesEdc = [];
      }
      console.log(JSON.stringify(item));
      this.itemEdpSelected.componentesEdc = this.itemEdpSelected.componentesEdc.concat(this.buildEdc([item], sistema));

      this.saveEdc(this.itemEdpSelected);
    },

    /*reloadEdc(edc){
      //this.fields.dataSource=val;
      //var tree = this.$refs.treeview;
      //tree.addNodes(edc);
      //tree.collapseAll();
  },*/
  },

};
</script>
<style>
.droppable {
  font-size: 14px;
  height: 100px;
  width: 100%;
  border: 1px solid #cecece;
  background: #f6f6f6;
  touch-action: none;
}


.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
  height: 60px;
}

.dragging .drop-zone {
  border: 1px dashed red;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>
