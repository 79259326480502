<template>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="card mt-4" v-if="!sessionExpired">
          <div class="card-header">
            <h5 class="mb-3">Actividades</h5>
            <div
              class="btn-toolbar justify-content-between"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <toolbar-button
                icon="add"
                color="success"
                title="Agregar actividad"
                @click="addHandler"
                :loading="false"
              />
            </div>
            <vue-good-table
              :columns="columns"
              :line-numbers="false"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar...',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
              }"
              :rows="actividades"
              style-class="vgt-table striped bordered condensed"
              compactMode
            >
              <template #table-row="props">
                <div v-if="props.column.field == 'name'">
                  <span
                    class="mr-2 cursor-pointer"
                    @click.prevent="selectHandler(props.row)"
                    >{{ props.row.name }} ( {{ props.row.code }} )</span
                  >
                </div>
                <span
                  v-else-if="props.column.field == 'actions'"
                  class="cursor-pointer"
                >
                  <a href="#" @click.prevent="deleteHandler(props.row)">
                    <i class="ni ni-fat-remove mx-2"></i>
                  </a>
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal-viewer :visible="editing" title="Tipo de área" @on-close="onClose">
      <slot>
        <div class="row">
          <div class="col-6 my-2">
            
            <material-input
              id="code"
              label="Código"
              variant="dynamic"
              :default-value="actividad.code"
              :success="actividad.code.length > 0"
              :error="actividad.code.length == 0"
              :is-required="true"
              @value-changed="
                (val) => {
                  actividad.code = val;
                }
              "
              ><i class="ni ni-circle-08 mt-3"></i
            ></material-input>
          </div>
          <div class="col-6 my-2">
            
            <material-input
              id="priority"
              label="Prioridad"
              variant="dynamic"
              :default-value="actividad.priority"
              :success="actividad.priority > 0"
              :error="actividad.priority == 0"
              :is-required="true"
              @value-changed="
                (val) => {
                  actividad.priority = val;
                }
              "
              ><i class="ni ni-circle-08 mt-3"></i
            ></material-input>
          </div>
          <div class="col-12 my-2">
            <material-input
              id="name"
              label="Nombre"
              variant="dynamic"
              :default-value="actividad.name"
              :success="actividad.name.length > 0"
              :error="actividad.name.length == 0"
              :is-required="true"
              @value-changed="
                (val) => {
                  actividad.name = val;
                }
              "
              ><i class="ni ni-circle-08 mt-3"></i
            ></material-input>
          </div>
        </div>
      </slot>
      <template #buttons>
        <button
          type="button"
          class="btn btn-primary full-width"
          @click="saveHandler()"
        >
          Aceptar
        </button>
      </template>
    </modal-viewer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import ModalViewer from "@/components/ui/ModalViewer.vue";
import MaterialInput from "@/components/ui/MaterialInput.vue";
import ToolbarButton from '@/components/ui/ToolbarButton';

export default {
  name: "Actividades",
  components: {
    ModalViewer,
    MaterialInput,
    ToolbarButton
  },
  props: {},
  data() {
    return {
      debug: process.env.VUE_APP_NODE_ENV == "development",
      columns: [
        { field: "priority", label: "Prioridad" },
        { field: "code", label: "Código" },
        { field: "name", label: "Actividad" },
        { field: "actions", label: "" },
      ],
      editing: false,
      actividad: {
        id: "",
        code: "",
        name: "",
        priority: 1
      },
    };
  },
  computed: {
    ...mapGetters([
      "actividades",
      "sessionExpired",
      "loading",
      "deleting",
      "selectedActividad",
    ]),
    ...mapState(["settings"]),
  },
  created() {},
  mounted() {
    this.loadActividades();
  },
  methods: {
    ...mapMutations(["selectActividad"]),
    ...mapActions(["loadActividades", "deleteActividad", "saveActividad"]),
    selectHandler(row) {
      this.selectActividad(row.id);
      this.actividad = { ...this.selectedActividad };
      this.editing = true;
    },
    deleteHandler(row) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "Confirma que deseas eliminar esta actividad",
        cancelButtonText: "Cancelar",
        type: "danger",
        showCancelButton: true,
        confirmButtonColor: "#66bb6a",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, estoy seguro",
      }).then((result) => {
        if (result.value) {
          this.deleteActividad(row._id);
        }
      });
    },
    addHandler() {
      this.selectActividad("");
      this.actividad = { ...this.selectedActividad };
      this.editing = true;
    },
    saveHandler() {
      if (this.saveActividad(this.actividad)) {
        this.editing = false;
      }
    },
    onClose() {
      this.editing = false;
    },
  },
};
</script>
