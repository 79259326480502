<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Datos financieros</h5>
    <p class="mb-0 text-sm">Ingresa la información financiera del proyecto</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-2 my-2">
          
          <select
            id="currency"
            class="form-control"
            name="choices-sizes"
            v-model="project.currency"
          >
            <option v-for="option in currencies" :key="option.code" :value="option.code">
              {{ option.label }}
            </option>
          </select>

        </div>
        <div class="col-10 my-2">
          <material-input
            id="budget"
            label="Presupuesto"
            variant="dynamic"
            :default-value="project.budget"
            :success="project.budget > 0"
            :error="project.budget == 0"
            :is-required="true"
            @value-changed="
              (val) => {
                project.budget = val;
              }
            "
            ><i class="ni ni-circle-08 mt-3"></i
          ></material-input>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 my-2">
          <material-input
            id="startYear"
            label="Año inicio de Obras"
            variant="dynamic"
            :default-value="project.startYear"
            :success="project.startYear > 0"
            :error="project.startYear == 0"
            :is-required="true"
            @value-changed="
              (val) => {
                project.startYear = val;
              }
            "
            ><i class="ni ni-circle-08 mt-3"></i
          ></material-input>
        </div>
        <div class="col-6 my-2">
          <material-input
            id="endYear"
            label="Año previsto de cierre"
            variant="dynamic"
            :default-value="project.endYear"
            :success="project.endYear > 0"
            :error="project.endYear == 0"
            :is-required="true"
            @value-changed="
              (val) => {
                project.endYear = val;
              }
            "
            ><i class="ni ni-circle-08 mt-3"></i
          ></material-input>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Anterior</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          :disabled="!isValid"
          @click="this.$parent.nextStep"
          >Siguiente</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/ui/MaterialInput.vue";
import MaterialButton from "@/components/ui/MaterialButton.vue";
import Choices from "choices.js";
import { mapGetters } from "vuex";
export default {
  name: "DatosFinancieros",
  components: {
    MaterialInput,
    MaterialButton
  },
  props: {},
  data(){
    return {
      currencies:[
        {code:"CLP",label:"CLP"}
        ,{code:"UF",label:"UF"}
        ,{code:"USD",label:"USD"}
        ,{code:"MUSD",label:"MUSD"}
        ,{code:"MMUSD",label:"MMUSD"}
      ]
    }
  },
  computed: {
    ...mapGetters(["project"]),
    isValid() {
      return this.project.startYear && this.project.endYear;
    },
  },
  methods: {},
  mounted(){
    if (document.getElementById("currency")) {
      let element = document.getElementById("currency");
      new Choices(element, {
        searchEnabled: false,
      });
    }
  }
};
</script>
