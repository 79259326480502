<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Datos del usuario</h5>
    <p class="mb-0 text-sm">Información básica</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <material-input id="firstName" label="Nombres" variant="dynamic" />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <material-input id="lastName" label="Apellidos" variant="dynamic" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <material-input id="company" label="Área" variant="dynamic" />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <material-input
            id="email"
            type="email"
            label="Correo electrónico"
            variant="dynamic"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <material-input
            id="password"
            type="password"
            label="Contraseña"
            variant="dynamic"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <material-input
            id="repeatPassword"
            type="password"
            label="Confirmar contraseña"
            variant="dynamic"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="this.$parent.nextStep"
          >Siguiente</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/ui/MaterialInput.vue";
import MaterialButton from "@/components/ui/MaterialButton.vue";

export default {
  name: "UserInfo",
  components: {
    MaterialInput,
    MaterialButton,
  },
};
</script>
