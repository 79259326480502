import { createRouter, createWebHistory } from "vue-router";
import Dashboard2 from "../views/dashboards/Dashboard2.vue";
import Sales from "../views/dashboards/Sales.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Projects from "../views/pages/profile/Projects.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/products/EditProduct.vue";
import ProductPage from "../views/ecommerce/products/ProductPage.vue";
import OrderDetails from "../views/ecommerce/orders/OrderDetails";
import OrderList from "../views/ecommerce/orders/OrderList";

import NewUser from "../views/pages/users/NewUser.vue";
import UserList from "../views/pages/users/UserList.vue";
import Settings from "../views/pages/account/Settings.vue";
import Billing from "../views/pages/account/Billing.vue";
import Invoice from "../views/pages/account/Invoice.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import SignupCover from "../views/auth/signup/Cover.vue";

import ProjectList from "../views/becca/projects/ProjectList.vue";
import NewProject from "../views/becca/projects/NewProject.vue";
import ProjectInfo from "../views/becca/projects/ProjectInfo.vue";
import ProjectEDP from "../views/becca/projects/ProjectEDP.vue";
//import ProjectSistemas from "../views/becca/projects/ProjectSistemas.vue";
import ProjectEDC from "../views/becca/projects/ProjectEDC.vue";
import ProjectGantt from "../views/becca/projects/ProjectGantt.vue";
import TiposArea from "../views/becca/TiposArea.vue"
import Componentes from "../views/becca/Componentes.vue"
import Sistemas from "../views/becca/Sistemas.vue"
import Actividades from "../views/becca/Actividades.vue"
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/home",
  },
  {
    path: "/projects",
    name: "Proyectos",
    component: ProjectList,
  },
  {
    path: "/projects/new",
    name: "Nuevo Proyecto",
    component: NewProject,
  },
  {
    path: "/projects/:id",
    name: "Proyecto - Home",
    component: ProjectInfo,
    props: route => ({
      id: route.params.id
    }),
  },
  {
    path: "/projects/:id/edp",
    name: "Proyecto - EDP",
    component: ProjectEDP,
    props: route => ({
      id: route.params.id
    }),
  },
  {
    path: "/projects/:id/edc",
    name: "Proyecto - EDC",
    component: ProjectEDC,
    props: route => ({
      id: route.params.id
    }),
  },
  {
    path: "/projects/:id/gantt",
    name: "Proyecto - Gantt",
    component: ProjectGantt,
    props: route => ({
      id: route.params.id
    }),
  },
 /* {
    path: "/projects/:id/sistemas",
    name: "Proyecto - Sistemas",
    component: ProjectSistemas,
    props: route => ({
      id: route.params.id
    }),
  },*/
  {
    path: "/admin/tipos-area",
    name: "Tipos de área",
    component: TiposArea,
  },
  {
    path: "/admin/actividades",
    name: "Actividades",
    component: Actividades,
  },
  {
    path: "/admin/sistemas",
    name: "Sistemas",
    component: Sistemas,
  },
  {
    path: "/admin/componentes",
    name: "Componentes",
    component: Componentes,
  },
  {
    path: "/usuarios/list",
    name: "Usuarios",
    component: UserList,
  },
  {
    path: "/usuarios/new",
    name: "Nuevo Usuario",
    component: NewUser,
  },
  {
    path: "/dashboards/dashboard-default-old",
    name: "Default",
    component: Dashboard2,
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Home",
    component: Dashboard2,
  },
  {
    path: "/home",
    name: "Home",
    component: Sales,
  },
  {
    path: "/login",
    name: "Login",
    component: Basic,
  },

  {
    path: "/dashboards/sales",
    name: "Sales",
    component: Sales,
  },
  {
    path: "/profile/overview",
    name: "Profile Overview",
    component: Overview,
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects,
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails,
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser,
  },
  {
    path: "/profile/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
