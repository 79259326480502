import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

const emptyActividad = {
  id: "",
  name: "",
  code: "",
  priority: "1"
}

const state = {
  selectedActividad: { ...emptyActividad },
  actividades: [],
  savingActividad: false
};

export default {
  state,
  getters: {
    selectedActividad: (state) => state.selectedActividad,
    actividades: (state) => state.actividades,
    savingActividad: (state) => state.savingActividad
  },
  mutations: {
    selectActividad(state, id) {
      if (id === "") {
        state.selectedActividad = { ...emptyActividad };
      } else {
        if (!state.actividades.find(item => item.id === id)) {
          return;
        }
        state.selectedActividad = { ...state.actividades.find(item => item.id === id) }
      }
    },
    setActividades(state, data) {
      state.actividades = data;
    },
    savingActividad(state, data) {
      state.savingActividad = data;
    }
  },
  actions: {
    async saveActividad({ commit, dispatch }, data) {
      commit("savingActividad", true);
      commit("loading", true);
      try {
        const request = {
          collection: "actividades",
          object: data
        }
        const saveObject = firebase.functions().httpsCallable("saveObject");
        const saveObjectResponse = await saveObject(request)
        //const savedObject = { ...saveObjectResponse.data.object };
        commit("message", saveObjectResponse.data.message);
        commit("savingActividad", false);
        commit("loading", false);
        dispatch("loadActividades");
        return true;
      } catch (error) {
        commit("setError", error.name || error.message)
        commit("savingActividad", false);
        commit("loading", false);
        return false;
      }
    },
    async deleteActividad({ commit, dispatch }, data) {
      try {
        commit("deleting", true);
        commit("loading", true);
        const removeObject = firebase.functions().httpsCallable("removeObject");
        const removeObjectResponse = await removeObject({ collection: "actividades", id: data });
        commit("deleting", false);
        commit("loading", false);
        commit("message", removeObjectResponse.data.message);
        dispatch("loadActividades");
      } catch (error) {
        commit("setError", error.name || error.message)
        commit("deleting", false);
        commit("loading", false);
      }
    },
    async loadActividades({ commit }) {
      try {
        commit("loading", true);
        const loadObjects = firebase.functions().httpsCallable("loadObjects");
        const loadObjectsResponse = await loadObjects({ collection: "actividades" });
        commit("setActividades", loadObjectsResponse.data)
        commit("message", "Actividades cargadas");
        commit("loading", false);
      } catch (error) {
        commit("loading", false);
        commit("setError", error.name || error.message)
        if (error.code && error.code.startsWith("auth")) {
          commit("setSessionExpired", true);
        }
      }
    },
  },
  created() {

  },
};
