<template>
  <div
  :title="component.name"
    class="drag-el icon icon-lg icon-shape bg-gradient-success shadow-info text-center border-radius-xl m-2"
  >
    <i class="material-icons opacity-10">{{icon}}</i>
    <div class="text-white mt-2" > {{ component.code }}</div>
  </div>
</template>

<script>
export default {
  name: "ComponentIcon",
  components: {},
  props: {
    icon: {
      type: String,
      default: "engineering",
    },
    component: {
      type: Object,
      default: function(){},
    },
  },
  data() {
    return {};
  },

  methods: {},
  mounted() {},
};
</script>
