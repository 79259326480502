<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      settings.isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${settings.sidebarType}`"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/home">
        <img
          :src="
            settings.sidebarType === 'bg-white' ||
            (settings.sidebarType === 'bg-transparent' && !settings.isDarkMode)
              ? logoDark
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
        <span class="ms-1 font-weight-bold text-white"
          >Becca</span
        >
      </router-link>
    </div>
    <hr class="horizontal bg-secondary  m-2 " />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-becca.png";
import logoDark from "@/assets/img/logo-becca.png";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
    };
  },
  computed: {
    ...mapState(["settings"]),
  },
};
</script>
