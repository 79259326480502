import { L10n, setCulture } from '@syncfusion/ej2-base';
import pdfViewerES from './pdfviewer-es.json';
import pdfViewerEN from './pdfviewer-en.json';
L10n.load({
    'es-CL': {
        'PdfViewer': pdfViewerES,
        'data': {
            "EmptyRecord": 'No se encontraron resultados',
            "True": 'true',
            "False": 'false',
            "InvalidFilterMessage": 'Filtro no válido',
            "GroupDropArea": 'Arrastra un encabezado de columna aquí para agruparla',
            "UnGroup": 'Desagrupar',
            "UnGroupButton": 'Agrupar',
            "GroupDisable": 'Esta columna no se puede agrupar',
            "FilterbarTitle": '\'s filtro',
            "EmptyDataSourceError": 'DataSource must not be empty at initial load since columns are generated from dataSource in AutoGenerate Column Grid',
            // Toolbar Items
            "Add": 'Agregar',
            "Edit": 'Editar',
            "Cancel": 'Cancelar',
            "Update": 'Modificar',
            "Delete": 'Eliminar',
            "Print": 'Imprimir'
        },
        'grid': {
            "EmptyRecord": 'No se encontraron resultados',
            "BatchSaveConfirm":'¿Estás seguro de modificar los datos?',
            "True": 'true',
            "False": 'false',
            "InvalidFilterMessage": 'Filtro no válido',
            "GroupDropArea": 'Arrastra un encabezado de columna aquí para agruparla',
            "UnGroup": 'Desagrupar',
            "UnGroupButton": 'Agrupar',
            "GroupDisable": 'Esta columna no se puede agrupar',
            "FilterbarTitle": '\'s filtro',
            "EmptyDataSourceError": 'DataSource must not be empty at initial load since columns are generated from dataSource in AutoGenerate Column Grid',
            // Toolbar Items
            "Add": 'Agregar Item',
            "Edit": 'Editar',
            "Cancel": 'Cancelar',
            "Update": 'Grabar',
            "Delete": 'Eliminar Item',
            "Print": 'Imprimir'
        }
    },
    'en-US': {
        'PdfViewer': pdfViewerEN,
        'treegrid': {
            "EmptyRecord": 'No se encontraron resultados',
            "True": 'true',
            "False": 'false',
            "InvalidFilterMessage": 'Filtro no válido',
            "GroupDropArea": 'Arrastra un encabezado de columna aquí para agruparla',
            "UnGroup": 'Desagrupar',
            "UnGroupButton": 'Agrupar',
            "GroupDisable": 'Esta columna no se puede agrupar',
            "FilterbarTitle": '\'s filtro',
            "EmptyDataSourceError": 'DataSource must not be empty at initial load since columns are generated from dataSource in AutoGenerate Column Grid',
            // Toolbar Items
            "Add": 'Agregar',
            "Edit": 'Editar',
            "Cancel": 'Cancelar',
            "Update": 'Modificar',
            "Delete": 'Eliminar',
            "Print": 'Imprimir'
        }
    }
});

setCulture('es-CL');