<template>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Lista de usuarios</h5>
          </div>
          <div class="table-responsive">
            <table id="user-list" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>Usuario</th>
                  <th>Correo electrónico</th>
                  <th>Área</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="( {id,firstName,lastName,userIcon,area,email} ) of users" :key="id">
                  <td>
                    <div class="d-flex align-items-center">
                      <material-checkbox />
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <material-avatar :img="userIcon" class="me-2" size="xs" circular alt="user image" />
                      <span> {{ lastName }}, {{firstName}}</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">{{ email }}</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">{{ area }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import MaterialAvatar from "@/components/ui/MaterialAvatar.vue";
import MaterialCheckbox from "@/components/ui/MaterialCheckbox.vue";
import nr from "../../../assets/img/nr.jpg";
import ff from "../../../assets/img/ff.jpg";

export default {
  name: "UserList",
  components: {
    MaterialAvatar,
    MaterialCheckbox,
  },
  data() {
    return {
      users: [{
        id: 1
        , firstName: "Norman Gabriel"
        , lastName:"Riquelme Aguayo"
        , email:"norman.riquelme@gmail.com"
        , userIcon: nr
        , area:"Externo"
        },{
        id: 2
        , firstName: "Francisco"
        , lastName:"Figueroa"
        , email:"francisco.figueroa@api-ux.com"
        , userIcon: ff
        , area:"Team"
      }]
    };
  },
  mounted() {
    if (document.getElementById("user-list")) {
      const dataTableSearch = new DataTable("#user-list", {
        searchable: true,
        fixedHeight: false,
        perPageSelect: false,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function (el) {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
  },
};
</script>
