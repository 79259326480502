<template>
  <button
    class="btn text-center shadow icon icon-shape mx-1 cursor-pointer  px-0"
    :class="`bg-gradient-${color}`"
    :title="title"
  >
    <i v-if="!loading" class="material-icons opacity-10 fs-3" aria-hidden="true">{{ icon }}</i>
    <span
        v-if="loading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
    ></span>
  </button>
</template>

<script>
export default {
  name: "ToolbarButton",
  props: {
    color: {
      type: String,
      default: "success",
    },
    icon: {
      type: String,
      default: "refresh",
    },
    title: {
      type: String,
      default: "",
    },
    loading:{
      type:Boolean,
      default: false
    }
  },
};
</script>
