import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const state = {
  idToken: localStorage.getItem("idToken") != null
    ? localStorage.getItem("idToken")
    : null,
  isSignedIn: false
  , loggedInUser: null/*
    localStorage.getItem("userInfo") != null
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,*/,
  loadingUser: false,
  sessionExpired: false,
  updatingUserInfo: false
};

const setCurrentUser = async function (commit, result) {
  /** @type {firebase.auth.OAuthCredential} */
  var credential = result.credential;
  // This gives you a Google Access Token. You can use it to access the Google API.
  var token = credential.idToken; //accessToken
  var user = result.user;

  let basicInfo = { birthday: "", location: "", phoneNumber: "", jobTitle: "" };
  const basicInfoRef = firebase.firestore().collection("users").doc(user.uid);
  await basicInfoRef.get().then((doc) => {
    if (doc.exists) {
      basicInfo = { ...doc.data() };
    }
  }).catch((error) => {
    console.log("Error getting document:", error);
  });
  const currentUser = {
    uid: user.uid
    , email: user.email
    , displayName: user.displayName
    , photoUrl: user.photoURL
    , idToken: token
    , givenName: result.additionalUserInfo.profile.given_name
    , familyName: result.additionalUserInfo.profile.family_name
    , locale: result.additionalUserInfo.profile.locale
    , admin: false
    , ...basicInfo
  };
  localStorage.setItem("idToken", token);
  commit("setUser", currentUser);
};

export default {
  state,
  getters: {
    isSignedIn: (state) => state.isSignedIn,
    loggedInUser: (state) => state.loggedInUser,
    sessionExpired: (state) => state.sessionExpired,
    loadingUser: (state) => state.loadingUser,
    updatingUserInfo: (state) => state.updatingUserInfo
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = {...data};
      state.isSignedIn = true;
      state.sessionExpired = false;
      state.loadingUser = false;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.isSignedIn = false;
      state.loadingUser = false;
      state.sessionExpired = true;
      // this.$router.go("/");
    },
    setLoadingUser(state, data) {
      state.loadingUser = data;
    },
    setSessionExpired(state, data) {
      state.sessionExpired = data;
    },
    updatingUserInfo(state, data) {
      state.updatingUserInfo = data;
    }
  },
  actions: {
    setLoadingUser({ commit }, data) {
      commit("clearError");
      commit("setLoadingUser", data);
    },
    login({ commit }, data) {
      commit("clearError");
      commit("setLoadingUser", true);
      /*
      firebase.auth().onAuthStateChanged(userAuth => {
        console.log("onAuthStateChanged", userAuth);
        if (userAuth) {
          firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(tokenResult => {
              console.log("claims", tokenResult.claims);
            });
        }
      });
      */
      firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)
        .then((response) => {
          response.user.getIdToken(false).then(function (idToken) {

            firebase
              .auth()
              .currentUser.getIdTokenResult()
              .then(tokenResult => {
                const currentUser = { uid: response.user.uid, email: response.user.email, displayName: response.user.displayName, idToken: idToken, admin: tokenResult.claims.admin || false };
                localStorage.setItem("userInfo", JSON.stringify(currentUser));
                commit("setUser", currentUser);
              });
          }).catch(function (error) {
            localStorage.removeItem("userInfo");
            commit("setError", error);
          });
        })
        .catch(function (error) {
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // console.log(error);
          localStorage.removeItem("userInfo");
          commit("setError", error);
          // ...
        });
    },
    updateUser({ commit }, data) {
      commit("updatingUserInfo", true);
      const currentUser = firebase.auth().currentUser;
      firebase.firestore().collection("users").doc(currentUser.uid).set(data).then(() => {
        commit("setMessage", "Usuario actualizado");
        commit("updatingUserInfo", false);
      }).catch((error) => {
        commit("setError", "Error writing document: ", error);
        commit("updatingUserInfo", false);
      });
    },/*para móvil probar signInWithRedirect*/
    async loginWithGoogle({ commit,dispatch }) {
      var provider = new firebase.auth.GoogleAuthProvider();
      commit("setLoadingUser", true);
      await firebase.auth()
        .signInWithPopup(provider)
        .then((result) => {
          setCurrentUser(commit, result);
          commit("setLoadingUser",false);
          dispatch("loadProjects");
        }).catch((error) => {
          var errorCode = error.code;
          console.log("errorCode", errorCode);
          var errorMessage = error.message;
          console.log("errorMessage", errorMessage);
          var email = error.email;
          console.log("email", email);
          var credential = error.credential;
          console.log("credential", credential);
          commit("setLoadingUser",false);
        });

    },
    refreshSession({ commit }) {
      if (firebase.auth().currentUser == null) {
        if (state.idToken != null) {
          var credential = firebase.auth.GoogleAuthProvider.credential(state.idToken);
          firebase.auth().signInWithCredential(credential).then((result) => {
            setCurrentUser(commit, result);
          }).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            if (errorCode == "auth/invalid-credential"
            ||errorCode=="auth/user-disabled"
            ) {
              commit("setSessionExpired", true);
            }
            /*
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
            console.log("res", { errorCode, errorMessage, email, credential })
            */
          });

        } else {
          commit("setSessionExpired", true);
        }
      }
    },
    signOut({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            //localStorage.removeItem("userInfo");
            localStorage.removeItem("idToken");
            commit("setLogout");
          },
          (_error) => { console.log(_error); }
        );
    },
    uploadToStorage(filedata, filehandle, DropzoneHandle, cb) {

      // Getting Handle of the progressbar element of current file // 
      var progressBar = filehandle.previewElement.children[2]

      // Firestore storage task 
      var task
      // uuid for file being uploaded
      var uuid_string = state.loggedInUser.uid;//uuid()
      console.log(uuid_string);
      // Getting Storeage referance for file 
      var storageRef = firebase.storage().ref(uuid_string + "/file");

      // Because I am uploading file in base64 data_url // 
      task = storageRef.putString(filedata, 'data_url');

      // Making progress bar of current file preview element visible  
      progressBar.opacity = 1

      task
        .on(firebase.storage.TaskEvent.STATE_CHANGED,

          // Trakcing progress of upload
          function progress(snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            // Updating progressbar width - to make it look like filling
            progressBar.children[0].style.width = progress + '%'

          },

          // Firebase storeage upload error handling 
          function (error) {
            console.log(error);
            // Hanlde your way
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
          },

          // Finishing process and returing data
          // Returning file-meta and  url
          // and hiding progress bar  
          function () {

            // Upload completed successfully, now we can get the meta data of file
            task.snapshot.ref.getMetadata().then(function (meta) {
              // Getting download url of file
              task.snapshot.ref.getDownloadURL().then(function (downloadUrl) {

                // storing meta data and download url in object and returning 
                // it to callign function ... 
                var response = {
                  object_info: {
                    publicURL: downloadUrl,
                    metainfo: meta
                  },
                  //type_of_object: type
                }
                filehandle.fullPath = meta.fullPath
                return cb(response)
              })
            })

            // Hiding progress bar for current file 
            progressBar.style.opacity = 0
          }
        )

    }
  },
  created() {

  },
};
