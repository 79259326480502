<template>
  <div class="fixed-plugin">
    <a  v-if="settings.isPinned" class="px-3 py-2 mb-6   fixed-plugin-button text-dark position-fixed" @click.prevent="minNav">
      <i class="material-icons py-2">subject</i>
    </a>
    <a v-if="!settings.isPinned" class="px-3 py-2 mb-6   fixed-plugin-button text-dark position-fixed" @click.prevent="minNav">
      <i class="material-icons py-2">reorder</i>
    </a>
    <a class="px-3 py-2 fixed-plugin-button text-dark position-fixed" @click="toggle">
      <i class="material-icons py-2">settings</i>
    </a>
    <div class="shadow-lg card">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Configuración Dashboard</h5>
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="material-icons">clear</i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <!-- Navbar Fixed -->
        <div class="mt-3 d-flex">
          <h6 class="mb-0">Barra de navegación fija</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input id="navbarFixed" class="mt-1 form-check-input" :class="settings.isRTL ? 'float-end  me-auto' : ' ms-auto'"
              type="checkbox" :checked="settings.isNavFixed" @click="setNavbarFixed" />
          </div>
        </div>
        <hr class="my-3 horizontal dark" />
        <div class="mt-2 d-flex">
          <h6 class="mb-0">Menú lateral angosto</h6>

          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input id="navbarMinimize" class="mt-1 form-check-input" :class="settings.isRTL ? 'float-end  me-auto' : ' ms-auto'"
              type="checkbox" @click="navbarMinimize" />
          </div>
        </div>
        <hr class="horizontal dark my-3" />
        <div class="mt-2 d-flex">
          <h6 class="mb-0">Fondo claro / Fondo Oscuro</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input class="form-check-input mt-1 ms-auto" type="checkbox" :checked="this.$store.state.isDarkMode"
              @click="darkMode" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";

export default {
  name: "Configurator",
  // eslint-disable-next-line vue/require-prop-types
  props: {
    minNav: {
      type: Function,
      default: () => { },
    },
    toggle: {
      type: Function,
      default: () => { },
    },
  },
  computed: {
    ...mapState([
     "settings"
    ]),
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    }
  },
  beforeMount() {
    window.addEventListener("resize", this.sidenavTypeOnResize);
    window.addEventListener("load", this.sidenavTypeOnResize);
  },
  methods: {
    ...mapMutations(["navbarMinimize", "navbarFixed"]),
    ...mapActions(["setColor"]),
    sidebarColor(color = "success") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.setColor(color);
    },

    sidebar(type) {
      this.$store.state.settings.sidebarType = type;
    },

    setNavbarFixed() {
      if (!this.settings.isAbsolute) {
        this.navbarFixed();
      } else {
        this.settings.absolute;
      }
    },

    darkMode() {
      if (this.$store.state.isDarkMode) {
        this.$store.state.isDarkMode = false;
        deactivateDarkMode();
        return;
      } else {
        this.$store.state.isDarkMode = true;
        activateDarkMode();
      }
    },

    sidenavTypeOnResize() {
      let transparent = document.querySelector("#btn-transparent");
      let white = document.querySelector("#btn-white");
      if (window.innerWidth < 1200) {
        if (transparent) {
          transparent.classList.add("disabled");
        }
        if (white) {
          white.classList.add("disabled");
        }
      } else {
        if (transparent) {
          transparent.classList.remove("disabled");
        }
        if (white) {
          white.classList.remove("disabled");
        }
      }
    }
  }
};
</script>
