import { createStore } from "vuex";
import settings from "./modules/settings";
import messages from "./modules/messages";
import auth from "./modules/auth";
import projects from "./modules/projects";
import tiposArea from "./modules/tipos-area";
import actividades from "./modules/actividades";
import sistemas from "./modules/sistemas";
import componentes from "./modules/componentes";
export default createStore({
 modules: {
    settings
    ,auth
    ,messages
    ,projects
    ,tiposArea
    ,sistemas
    ,actividades
    ,componentes
  }
});