import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

const state= {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap
  };


const getters={
  color: (state) => state.color
};

const mutations={
  toggleConfigurator(state) {
    state.showConfig = !state.showConfig;
  },
  toggleEveryDisplay(state) {
    state.showNavbar = !state.showNavbar;
    state.showSidenav = !state.showSidenav;
    state.showFooter = !state.showFooter;
  },
  toggleHideConfig(state) {
    state.hideConfigButton = !state.hideConfigButton;
  },
  setIsPinned(state, payload) {
    state.isPinned = payload;
  },
  color(state, payload) {
    state.color = payload;
  },
  navbarMinimize(state) {
    const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

    if (sidenavShow.classList.contains("g-sidenav-pinned")) {
      sidenavShow.classList.remove("g-sidenav-pinned");
      sidenavShow.classList.add("g-sidenav-hidden");
      state.isPinned = true;
    } else {
      sidenavShow.classList.remove("g-sidenav-hidden");
      sidenavShow.classList.add("g-sidenav-pinned");
      state.isPinned = false;
    }
  },
  navbarFixed(state) {
    if (state.isNavFixed === false) {
      state.isNavFixed = true;
    } else {
      state.isNavFixed = false;
    }
  }
};

const actions= {
  setColor({ commit }, payload) {
    commit("color", payload);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};