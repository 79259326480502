<template>
  <div class="card mb-4">
    <div class="d-flex">
      <div
        class="icon icon-shape icon-lg bg-gradient-success shadow text-center border-radius-xl mt-n3 ms-4"
      >
        <i class="material-icons opacity-10" aria-hidden="true">house_siding</i>
      </div>
      <h6 class="mt-3 mb-2 ms-3">{{ title }}</h6>
    </div>
    <div class="card-body p-3">
      <div class="row">
        
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "EncabezadoProyecto",
  props: {
    title: {
      type: String,
      default: "Información del proyecto",
    }
  },

  mounted() {
    
  },
};
</script>
