<template>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="card mt-4" v-if="!sessionExpired">
          <div class="card-header">
            <h5 class="mb-3">Componentes principales</h5>
            <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
              <toolbar-button icon="add" color="success" title="Agregar componente" @click="addHandler"
                :loading="false" />
            </div>
            <vue-good-table :columns="columns" :line-numbers="false" :search-options="{
              enabled: true,
              placeholder: 'Buscar...',
            }" :pagination-options="{
  enabled: true,
  mode: 'records',
}" :rows="componentes" style-class="vgt-table striped bordered condensed" compactMode>
              <template #table-row="props">
                <div v-if="props.column.field == 'name'">
                  <span class="mr-2 cursor-pointer" @click.prevent="selectHandler(props.row)">{{ props.row.name }} ( {{
                    props.row.code }} )</span>
                </div>
                <span v-else-if="props.column.field == 'actions'" class="cursor-pointer">
                  <a href="#" @click.prevent="deleteHandler(props.row)">
                    <i class="ni ni-fat-remove mx-2"></i>
                  </a>
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal-viewer :visible="editing" title="Componente Principal" @on-close="onClose">
      <slot>
        <div class="row">
          <div class="col-6 my-2">

            <material-input id="code" label="Identificación" variant="dynamic" :default-value="componente.code"
              :success="componente.code.length > 0" :error="componente.code.length == 0" :is-required="true"
              @value-changed="(val) => {
                componente.code = val;
              }
                "><i class="ni ni-circle-08 mt-3"></i></material-input>
          </div>
          <div class="col-6 my-2">

            <material-input id="priority" label="Prioridad" variant="dynamic" :default-value="componente.priority"
              :success="componente.priority > 0" :error="componente.priority == 0" :is-required="true" @value-changed="(val) => {
                componente.priority = val;
              }
                "><i class="ni ni-circle-08 mt-3"></i></material-input>
          </div>
          <div class="col-12 my-2">
            <material-input id="name" label="Nombre" variant="dynamic" :default-value="componente.name"
              :success="componente.name.length > 0" :error="componente.name.length == 0" :is-required="true"
              @value-changed="(val) => {
                componente.name = val;
              }
                "><i class="ni ni-circle-08 mt-3"></i></material-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 my-2">
            <h5 class="mb-2">Componentes básicos</h5>
            <ejs-grid :dataSource='componente.subcomponents' :editSettings='editSettings' :sortSettings='sortSettings'
              :toolbar='toolbar' :beforeBatchAdd="beforeBatchAdd" :beforeBatchSave="beforeBatchSave"
              :actionComplete="gridActionHandler" :actionBegin="gridActionHandler" :allowSorting="true"
              :rowSelected="selectSubcomponente">
              <e-columns>
                <e-column field='id' headerText='Id' textAlign='left' width=50 aria-readonly="true" :allowEditing="false"
                  :allowSorting="true"></e-column>
                <e-column field='code' headerText='Identificación' textAlign='left' width=120
                  :allowSorting="false"></e-column>
                <e-column field='name' headerText='Nombre' textAlign='left' :allowSorting="false"></e-column>
                <e-column field='priority' headerText='Prioridad' textAlign='right' width=120
                  :allowSorting="true"></e-column>
              </e-columns>
            </ejs-grid>
          </div>
          <div class="col-12 my-2" v-if="subcomponente != null">
            <ejs-listview id='actividades' :dataSource='actividadesSubcomponente' showCheckBox='true'
              :headerTitle="`${subcomponente.name} - Actividades`" showHeader='true'
              :fields="{ text: 'name', tooltip: 'name', id: 'id', isChecked: 'checked' }"
              @select="selectActividad"></ejs-listview>
          </div>
        </div>
      </slot>
      <template #buttons>
        <button type="button" class="btn btn-primary full-width" @click="saveHandler()">
          Aceptar
        </button>
      </template>
    </modal-viewer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import ModalViewer from "@/components/ui/ModalViewer.vue";
import ToolbarButton from "@/components/ui/ToolbarButton.vue";
import MaterialInput from "@/components/ui/MaterialInput.vue";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar, Edit, Sort
} from "@syncfusion/ej2-vue-grids";

export default {
  name: "Componentes",
  components: {
    ModalViewer,
    ToolbarButton,
    MaterialInput,
    "ejs-grid": GridComponent,
    "e-columns": ColumnsDirective,
    "e-column": ColumnDirective,
  },
  props: {},
  data() {
    return {
      debug: process.env.VUE_APP_NODE_ENV == "development",
      columns: [
        { field: "priority", label: "Prioridad" },
        { field: "name", label: "Componente" },
        { field: "actions", label: "" },
      ],
      editing: false,
      componente: {
        id: "",
        code: "",
        name: "",
        priority: 1,
        subcomponents: [],
      },
      subcomponente: null,
      actividadesSubcomponente: [],
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal', newRowPosition: "Below", showConfirmDialog: false,
        showDeleteConfirmDialog: false
      },
      sortSettings: { allowUnsort: false, columns: [{ field: 'id', direction: 'Ascending' }] },
      toolbar: ['Add', 'Edit', 'Delete', 'Update', 'Cancel'],
    };
  },
  provide: {
    grid: [Page, Edit, Toolbar, Sort]
  },
  computed: {
    ...mapGetters([
      "componentes",
      "actividades",
      "sessionExpired",
      "loading",
      "deleting",
      "selectedComponente"
    ]),
    ...mapState(["settings"]),
  },
  created() { },
  mounted() {
    this.loadComponentes();
    this.loadActividades();
  },
  methods: {
    ...mapMutations(["selectComponente"]),
    ...mapActions(["loadComponentes", "loadActividades", "deleteComponente", "saveComponente"]),
    selectHandler(row) {
      this.selectComponente(row.id);
      this.componente = { ...this.selectedComponente };
      this.editing = true;
      this.clearPopup();
    },
    deleteHandler(row) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "Confirma que deseas eliminar este componente",
        cancelButtonText: "Cancelar",
        type: "danger",
        showCancelButton: true,
        confirmButtonColor: "#66bb6a",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, estoy seguro",
      }).then((result) => {
        if (result.value) {
          this.deleteComponente(row._id);
        }
      });
    },
    addHandler() {
      this.selectComponente("");
      this.clearPopup();
      this.componente = { ...this.selectedComponente };
      this.editing = true;
    },
    async saveHandler() {
      const isSaved=await this.saveComponente(this.componente);
      if (isSaved) {
        this.componente.subcomponents.splice();
        this.editing = false;
      }
    },
    beforeBatchAdd: function (args) {
      args.defaultData.id = Date.now();
      args.defaultData.code = "";
      args.defaultData.name = "";
      args.defaultData.priority = 1;
    },
    beforeBatchSave: function (batch) {
      let items = this.componente.subcomponents;
      console.log("original items", JSON.stringify(items));
      if (items && items.length > 0) {
        items = [...items, ...batch.batchChanges.addedRecords]
      } else {
        items = [...batch.batchChanges.addedRecords]
      }
      //items modificados
      batch.batchChanges.changedRecords.forEach((changedItem) => {
        const index = items.findIndex((originalItem) => originalItem.id === changedItem.id);
        if (index > -1) {
          if (items[index].code != changedItem.code) {
            items[index].code = changedItem.code
          }
          if (items[index].name != changedItem.name) {
            items[index].name = changedItem.name
          };
        }
      });
      if (batch.batchChanges.deletedRecords.length > 0) {
        items = items.filter((item) => {
          const index = batch.batchChanges.deletedRecords.findIndex((deletedItem) => deletedItem.id === item.id);
          return index < 0;
        });
      }
      this.componente.subcomponents = [...items];
    },
    gridActionHandler: function (args) {
      const { requestType, type, data, action } = args;
      // eslint-disable-next-line

      if (data) {
        if (requestType == "add" && type == "actionBegin") {
          const maxId = this.componente.subcomponents.reduce((maxValue, currentValue) => {
            return Math.max(maxValue, Number(currentValue.id));
          }, 0);
          const maxPriority = this.componente.subcomponents.reduce((maxValue, currentValue) => {
            return Math.max(maxValue, Number(currentValue.priority));
          }, 0);
          args.data.id = maxId + 1;
          args.data.priority = maxPriority + 1;
          console.log("args.data", JSON.stringify(args.data));
        } else if (action == "edit" && requestType == "save") {
          if (type == "actionBegin") {
            /*const modified = data.taskData
            ? data.code != data.taskData.code || data.name != data.taskData.name
            : true;
            */
          } else if (type == "actionComplete") {
            const gridItem = {
              id: data.id,
              code: data.code,
              name: data.name,
              priority: data.priority
            };
            const item = this.componente.subcomponents.find(item => item.id === gridItem.id)
            if (item) {
              const modified = gridItem.code != item.code || gridItem.name != item.name || gridItem.priority != item.priority;
              if (modified) {
                item.code = gridItem.code;
                item.name = gridItem.name;
                item.priority = gridItem.priority;
              } 
            }

          }
        } if (action == "add" && requestType == "save") {
          if (type == "actionComplete") {
            // if(this.componente.subcomponents.length==0){
            const gridItem = {
              id: data.id,
              code: data.code,
              name: data.name,
              priority: data.priority,
              actividades: []
            };
            this.componente.subcomponents.push(gridItem);
            //}
          }

        } else if (requestType == "delete" && type == "actionComplete") {
          const ids = args.data.map(item => item.id);
          const subcomponents = this.componente.subcomponents.filter(item => !ids.find(id => id === item.id));
          this.componente.subcomponents = [...subcomponents];
        }
        return;
      }
    },
    selectSubcomponente(row) {
      const id = row.data.id;
      this.subcomponente = this.selectedComponente.subcomponents.find((item) => item.id == id);
      if (this.subcomponente) {
        if (!("actividades" in this.subcomponente)) {
          this.subcomponente.actividades = [];
        }
      }
      if (this.subcomponente) {
        const actividadesSubcomponente = this.actividades.map((actividad) => {
          const checked = (this.subcomponente.actividades.find(item => item.id == actividad.id) != null);
          return {
            ...actividad,
            checked: checked
          };
        });

        actividadesSubcomponente.sort((item1, item2) => {
          return Number(item2.priority) < Number(item1.priority)
        });
        this.actividadesSubcomponente = [...actividadesSubcomponente];
      }
    },
    selectActividad(args) {
      const id = args.data.id;
      const checked = args.isChecked;
      this.actividadesSubcomponente.find(item => {
        if (item.id == id) {
          item.checked = checked;
        }
      })
      const actividades = this.actividadesSubcomponente.filter(item => item.checked).map(item => {
        return {
          id: item.id,
          name: item.name,
          code: item.priority
        }
      });
      this.subcomponente.actividades = [...actividades];
    },
    clearPopup() {
      this.subcomponente = null;
      this.actividadesSubcomponente = [];
    },
    onClose() {
      this.editing = false;
    },
  },
};
</script>
