<template>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="card mt-4" v-if="!sessionExpired">
          <div class="card-header">
            <h5 class="mb-3">Lista de proyectos</h5>
            <vue-good-table
              :columns="columns"
              :line-numbers="false"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar...',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
              }"
              :rows="projects"
              v-on:row-click="onRowClick"
              style-class="vgt-table striped bordered condensed"
              compactMode
            >
              <template #table-row="props">
                <div v-if="props.column.field == 'name' ">
                  <span class="mr-2 cursor-pointer" @click.prevent="selectProjectHandler(props.row)">{{ props.row.name }} ( {{props.row.code}} )</span>
                </div>
                <div v-if="props.column.field == 'budget' ">
                  <span class="mr-2 cursor-pointer" @click.prevent="selectProjectHandler(props.row)">{{ props.row.currency }}{{ props.row.budget }}</span>
                </div>
                <div v-if="props.column.field == 'date'">
                  <span class="mr-2  cursor-pointer">{{ props.row.date?format_date(props.row.date):"" }}</span>
                </div>
                <span v-else-if="props.column.field == 'actions'" class="cursor-pointer">
                  <a href="#" @click.prevent="deleteProjectHandler(props.row)">
                    <i class="ni ni-fat-remove mx-2"></i>
                  </a>
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>     
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters,mapActions,mapState,mapMutations } from 'vuex';
export default {
  name: "ProjectList",
  components: {
  },
  props: {
  },
  data() {
    return {
      debug: process.env.VUE_APP_NODE_ENV=="development",
      columns: [
       { field: "name", label: "Nombre" },
        { field: "budget", label: "Presupuesto" },
       { field: "actions", label: "" },
      ],
    };
  },
  computed: {
    ...mapGetters(["projects", "loadingProjects", "deletingProject","sessionExpired","project"]),
    ...mapState(["settings"]),
  },
  created() {
    
  },
  mounted() {
    this.loadProjects();
  },
  methods: {
    ...mapMutations(["clearProjects"]),
    ...mapActions(["loadProjects","selectProject","deleteProject"]),
    onRowClick(params) {
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument
      // indicates selected or not
      // params.event - click event
      //this.viewDocumentHandler(params.row);
      //this.selectDocument({...params.row});
     // this.selectProject(params.row.id);
      //this.$router.push(`/documents/${this.document.folder}/${this.document.id}`);
      if(this.debug){
        console.log(params);
      }
    },
    selectProjectHandler(row){
      this.selectProject(row.id);
    },
    deleteProjectHandler(row){
      this.$swal({
        title: "¿Estás seguro?",
        text: "Confirma que deseas eliminar el proyecto",
        cancelButtonText: "Cancelar",
        type: "danger",
        showCancelButton: true,
        confirmButtonColor: "#66bb6a",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, estoy seguro",
      }).then((result) => {
        if (result.value) {
          this.deleteProject(row._id);
        }
      });
      
    },
    format_date(value){
         if (value) {
          const date=new Date(value._seconds*1000);
          const formatted=moment(String(date)).format("DD-MM-YYYY HH:mm");
          return formatted;
        }
      },
  }
};
</script>
