<template>
  <!--https://ej2.syncfusion.com/vue/documentation/treegrid/getting-started-vue-3-->
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <encabezado-proyecto :title="currentProject.name" />
      </div>
    </div>
    <div class="row">
      <div class="control-section">
        <div class="col-12 control-section">
          <ejs-treegrid
            ref="grid"
            class="w-100"
            :allowSorting="true"
            :dataSource="edp"
            idMapping="id"
            parentIdMapping="parentId"
            :treeColumnIndex="1"
            :selectedRowIndex="2"
            :editSettings="editSettings"
            :toolbar="toolbar"
            :allowRowDragAndDrop="false"
            :allowPaging="true"
            :pageSettings="pageSettings"
            :sortSettings="sortSettings"
            locale="es-CL"
            :toolbarClick="toolbarClickHandler"
            :actionComplete="gridActionHandler"
            :actionBegin="gridActionHandler"
            :enableImmutableMode="true"
            :beforeBatchAdd="beforeBatchAdd"
            :beforeBatchSave="beforeBatchSave"
            :rowSelected="rowSelected"
            :rowDeselected="rowDeselected"
            height="700px"
          >
            <e-columns>
              <e-column
                field="id"
                headerText="Id"
                textAlign="Right"
                width="100"
                isPrimaryKey="true"
                :validationRules="idrules"
              ></e-column>
              <e-column
                field="code"
                headerText="Código"
                textAlign="Left"
                width="200"
                editType="stringedit"
                :validationRules="coderules"
              ></e-column>
              <e-column
                field="name"
                headerText="Nombre"
                textAlign="Left"
                width="300"
                editType="stringedit"
                :validationRules="namerules"
              ></e-column>
            </e-columns>
          </ejs-treegrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-vue-treegrid";
import { mapGetters, mapActions } from "vuex";
import EncabezadoProyecto from "./components/EncabezadoProyecto.vue";
import { Edit, Page, Toolbar, RowDD, Sort } from "@syncfusion/ej2-vue-treegrid";

export default {
  name: "ProjectEDP",
  components: {
    EncabezadoProyecto,
    "ejs-treegrid": TreeGridComponent,
    "e-columns": ColumnsDirective,
    "e-column": ColumnDirective,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      maxId:0,
      editSettings: {
        allowDeleting: true,
        allowEditing: true,
        allowAdding: true,
        mode: "Row",
        newRowPosition:"Below",
        allowNextRowEdit: true,
        showConfirmDialog:false,
        showDeleteConfirmDialog:false 

      },
      editparams: { params: { format: "n" } },
      //idrules: { required: true,number:true },
      idrules: {},
      coderules: { required: true },
      namerules: { required: true },
      toolbar: ["Add", "Delete", "Update", "Cancel", "Indent", "Outdent", { text: 'Grabar EDP', tooltipText: 'Grabar los cambios del EDP', prefixIcon: 'e-save', id: 'save-edp' }],
      pageSettings: { currentPage: 1, pageSize: 50, pageCount: 2 },
      sortSettings: {
        columns: [
          { field: "id", direction: "Ascending" },
          { field: "code", direction: "Ascending" },
          { field: "name", direction: "Ascending" },
        ],
      },
      selectedItem: null
    };
  },
  provide: {
    treegrid: [Edit, Page, Sort, Toolbar, RowDD],
  },
  computed: {
    ...mapGetters(["currentProject", "edp", "sessionExpired"]),
    edpForWatcher() {
        return [...this.edp];
    }
  },
  watch:{
    edpForWatcher(/**/){
      //console.log("edpForWatcher",val);
      this.$refs.grid.refresh();
    },
    id(val){
      this.selectProject(val);
    },
  },
  created() {},
  mounted() {
    if (this.id === "current") {
      console.log("proyecto actual");
    } else {
      if (this.currentProject.id != this.id) {
        this.selectProject(this.id);
      }
    }
  },
  methods: {
    ...mapActions(["selectProject", "saveEdp", "reloadEdp", "updateItemEdp"]),
    rowDeselected:function(){
      this.selectedItem=null;
    },
    rowSelected:function(args){
      this.selectedItem={...args.data};
    },
    toolbarClickHandler:function(args){
        if (args.item.properties.id === 'save-edp') {
          this.$swal({
          title: "¿Estás seguro?",
          text: "Confirma que grabar los cambios realizados al EDP",
          cancelButtonText: "Cancelar",
          type: "danger",
          showCancelButton: true,
          confirmButtonColor: "#66bb6a",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, estoy seguro",
        }).then((result) => {
          if (result.value) {
            this.saveEdp(this.edp);
          }
        });
       }
       args.cancel=false;
    },
    gridActionHandler: function (args) {
      const { requestType, type, data,action } = args;
      /*console.log("args", args);
      console.log("action",action);
      console.log("requestType",requestType);
      console.log("type",type);*/
      if(data){
        if (requestType == "add" && type == "actionBegin") {
          
          console.log(JSON.stringify(this.selectedItem));
          const max = this.edp.reduce((maxValue, currentValue) => {
            return Math.max(maxValue, Number(currentValue.id));
          }, -Infinity);
          args.data.id=max+1;
          if(this.selectedItem){
            args.data.code=this.selectedItem.code;
            if(this.selectedItem.parentId){
              args.data.parentId=this.selectedItem.parentId;
            }
          }

        }else if (action=="edit" && requestType == "save") {
          if(type == "actionBegin"){
            /*const modified = data.taskData
            ? data.code != data.taskData.code || data.name != data.taskData.name
            : true;
            */
          }else if(type == "actionComplete"){
            const gridItem = {
              id: data.id,
              code: data.code,
              name: data.name,
            };
            const item=this.edp.find(item => item.id === gridItem.id)
            console.log("item",item);
            if(item){
              const modified = gridItem.code != item.code || gridItem.name != item.name;
              console.log("modified",modified);
              if(modified){
                item.code=gridItem.code;
                item.name=gridItem.name;
                console.log("modified",item);
              }else{
                console.log("sin cambios");
              }
            }
           
          }
        } if (action=="add" && requestType == "save") {
          if(type == "actionBegin"){
            /**/
            
          }else if(type == "actionComplete"){
            args.cancel=true;
            const gridItem = {
              id: data.id,
              code: data.code,
              name: data.name,
            };
            if(args.data.parentId){
              gridItem.parentId=args.data.parentId;
            }
          }
          
        } else if (requestType == "delete" && type == "actionComplete") {
          const ids=args.data.map(item => item.id);
          console.log("ids",ids);
          const edp=this.edp.filter(item => !ids.find(item.id));
          this.reloadEdp(edp);
          //es una lista de elementos
         /* const item = {
            id: data[0].id,
            code: data[0].code,
            name: data[0].name,
          };
          this.updateItemEdp({ action: "delete", item });
          */
        } else if (requestType == "indented") {
          //es una lista de elementos
         /* const item = {
            id: data[0].id,
            parentId: data[0].parentId
          };
          this.updateItemEdp({ action: "indent", item });*/
        }else if (requestType == "outdented") {
          //es una lista de elementos
         /* const item = {
            id: data[0].id,
            parentId: data[0].parentId
          };
          this.updateItemEdp({ action: "outdent", item });*/
        }
       return;
      }
    },
    beforeBatchAdd: function (args) {
      //const edp=[...this.edp];
        if(this.maxId==0){
          const max = this.edp.reduce((maxValue, currentValue) => {
            return Math.max(maxValue, Number(currentValue.id));
          }, -Infinity);
          this.maxId=max;
        }
        args.defaultData.id=++this.maxId;
    },
    beforeBatchSave: function (batch) {
      //const edp=[...this.edp];
      this.saveEdp(batch.batchChanges);
      //si es que hay un error, se recarga la anterior
      //batch.cancel=true;
      //this.reloadEdp(edp);
    },
  },
};
</script>
<style>
</style>
